import { Dispatch, memo, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export const ChoosePlanType = memo<{ type: boolean; setType: Dispatch<SetStateAction<boolean>> }>(
  ({ type, setType }) => {
    const { t } = useTranslation('account');

    // @render

    return (
      <Container>
        <PlanTypeText>{type ? t('monthly') : t('yearly')}</PlanTypeText>
        <PlanTypeSwitch type="checkbox" onChange={() => setType(!type)} />
      </Container>
    );
  }
);

const Container = styled.div`
  transform: translateX(-2.4vw);
`;

const PlanTypeText = styled.p`
  text-align: center;
  margin-bottom: 2px;
`;

const PlanTypeSwitch = styled.input`
  position: relative;
  width: 80px;
  height: 40px;
  -webkit-appearance: none;
  appearance: none;
  background: #ac8ace;
  outline: none;
  border-radius: 2rem;
  cursor: pointer;
  box-shadow: inset 0 0 5px rgb(0 0 0 / 50%);

  &::before {
    content: '';
    width: 31px;
    height: 31px;
    border-radius: 50%;
    background: #fff;
    position: absolute;
    top: 4px;
    left: 5px;
    transition: 0.5s;
  }

  &:checked::before {
    transform: translateX(125%);
    background: #fff;
  }

  &:checked {
    background: #431075;
  }
`;
