import { ReactComponent as Long } from 'assets/img/icons/long.svg';
import { ReactComponent as Medium } from 'assets/img/icons/medium.svg';
import { ReactComponent as Short } from 'assets/img/icons/short.svg';
import { memo, useCallback, useState } from 'react';
import styled from 'styled-components';
import { TextLengthOption } from 'utils/types';

export const ArticleDrawerTextLength = memo<{ onChange: (newValue: TextLengthOption) => void }>(({ onChange }) => {
  const [textLength, setTextLength] = useState<TextLengthOption>('short');

  // @handlers

  const handleTextLengthChange = useCallback(
    (value: TextLengthOption) => () => {
      setTextLength(value);
      onChange(value);
    },
    [onChange]
  );

  // @render

  return (
    <Container>
      <ShortIcon onClick={handleTextLengthChange('short')} isActive={textLength === 'short'} />
      <MediumIcon onClick={handleTextLengthChange('medium')} isActive={textLength === 'medium'} />
      <LongIcon onClick={handleTextLengthChange('long')} isActive={textLength === 'long'} />
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > :not(:last-child) {
    margin-right: 10px;
  }
`;

const ShortIcon = styled(Short)<{ isActive: boolean }>`
  width: 182px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.3);
    color: ${({ theme }) => theme.colors.black};
  }

  ${({ isActive }) =>
    isActive &&
    `
    background-color: rgba(0, 0, 0, 0.3);
    color: black;
  `}
`;
const MediumIcon = styled(Medium)<{ isActive: boolean }>`
  width: 182px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.3);
    color: ${({ theme }) => theme.colors.black};
  }

  ${({ isActive }) =>
    isActive &&
    `
  background-color: rgba(0, 0, 0, 0.3);
  color: black;
`}
`;
const LongIcon = styled(Long)<{ isActive: boolean }>`
  width: 182px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.3);
    color: ${({ theme }) => theme.colors.black};
  }

  ${({ isActive }) =>
    isActive &&
    `
  background-color: rgba(0, 0, 0, 0.3);
  color: black;
`}
`;
