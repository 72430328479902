const translations = {
  verifySignUp: {
    verifying: 'Verifying...'
  },
  welcome: {
    //new
    loginToAccount: 'Login to your account',
    email: 'Email Address',
    password: 'Password',
    submit: 'Submit',

    passwordExpired: 'Password Expired',
    passwordExpiredDescription:
      "It's time to change your password for security purposes! Please check your email to reset your password.",
    resetPassword: 'Reset Password',
    okay: 'Okay',

    forgotPassword: 'Forgot Password',
    successForgotPassword: 'Success! Please check your email for a password reset link',
    errorForgotPassword: 'Error! Please try again',
    emptyForgotPassword: 'Please enter your email address',

    signIn: 'Sign In',
    signUpMessage: "Don't have an account? Sign Up",

    //old
    labels: {
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email Address',
      password: 'Password',
      passwordAgain: 'Password (again)',
      keepMeLogged: 'Keep Me Logged In',
      forgotPassword: 'Forgot Password',
      signUpMessage: "Don't have an account? Sign Up",
      novusCode: 'Novus Authentication Code',
      goBack: '← Back to Login',
      submit: 'Submit',
      signUp: 'Sign Up',
      signIn: 'Sign In'
    },
    message: {
      mailOrPasswordIncorrect: 'Email or password incorrect.',
      noMobile: 'No mobile entry.',
      mailSendSuccess: 'Password reset mail sending successfully.',
      inValidName: 'Please enter a valid Name.',
      inValidSurname: 'Please enter a valid Surname.',
      inValidMail: 'Please enter a valid email.',
      minPasswordChar: 'Password must be at least {{min}} characters.',
      signUpSuccess: 'Signup successful!',
      mailTaken: 'This email is taken.',
      inValidNovusCode: 'Please get a valid code from Novus team.',
      readyHaveAccount: 'Already have an account? Sign in',
      dontHaveNovusCode: "Don't have an authentication code? Get one!",
      resetPasswordSuccess: 'Password changed succesfully',
      resetPasswordError: 'The password could not be changed.',
      differentPasswords: 'Passwords are different.',
      emptyPassword: 'Password cannot be empty.',
      passwordLength: 'Password can be a minimum of {{min}} and a maximum of {{max}} characters.'
    }
  },
  stats: {
    totalWordCount: '📄 Total Word Count'
  },
  home: {
    home: 'Home',
    adminPanel: 'Admin Panel',
    quickActions: 'Quick Actions',
    signOut: 'Sign Out',
    signOutTitle: 'Sign out from account',
    signOutDescription: 'Are you sure you want to sign out? You will be redirected to the login page.',
    hotTopics: 'Hot Topics',
    myFolders: 'My Folders',
    draft: 'Draft',
    lastUpdatedAt: 'Last update',
    profile: 'Profile',
    helpCenter: 'Help Center',
    recentlyUsedTemplates: 'Recently Used Templates',
    update: 'Update',
    search: 'Search',
    placeholderImage: "Enter your dream here and we'll make it come true.",

    generateImage: 'Generate Image',
    newProject: 'New Project',
    generateText: 'Generate Text',
    upgradePlan: 'Upgrade Plan',

    popularTopics: 'Popular Topics',
    technology: 'Technology',
    science: 'Science',
    cryptocurrency: 'Cryptocurrency',
    climateChange: 'Climate Change',
    economy: 'Economy',

    showAll: 'Show All',
    showFolders: 'Show Folders',

    newFolder: 'New Folder',
    selectFolder: 'Select Folder',
    moveArticle: 'Move Article',

    download: 'Download PDF',
    folder: 'Folder',
    createNewProject: 'Create New Project',
    customTemplate: 'Custom Template',
    folderName: 'Folder Name',
    projectName: 'Project Name',
    create: 'Create',
    save: 'Save',
    saveProject: 'Save Project',
    editFolder: 'Edit Folder',
    editProject: 'Edit Project',
    moveProjectInfo: 'We already saved this document as a draft. Do you want to move this project in a folder?',
    moveProject: 'Move project',
    moveToFolder: 'Move to folder',
    selectFolderTo: 'Select a folder to move the project',
    move: 'Move',
    edit: 'Edit',
    deleteProject: 'Delete Project',
    deleteFolder: 'Delete Folder',
    delete: 'Delete',
    skipThisForNow: 'Skip this for now',

    notEmptyProjectName: 'Project name cannot be empty.',
    notEmptyFolderName: 'Folder name cannot be empty.',
    deleteProjectTitle: 'Are you sure you want to delete the project?',
    deleteFolderTitle: 'Are you sure you want to delete the folder?',
    deleteProjectInfo: 'If you delete the project, all the data you saved will be permanently deleted.',
    deleteFolderInfo: 'If you delete the folder, all the data you saved will be permanently deleted.',
    logout: 'Log Out',

    notFoundSaveEditor: 'No saved editorial was found.'
  },
  account: {
    homepage: 'Homepage',
    personalInformation: 'Personal Information',
    passwordInformation: 'Password',
    subscription: 'Subscription',
    upgradePlan: 'Upgrade Plan',

    orderSummary: 'Order Summary',
    billing: 'Billing',
    planCanceled: 'Your plan is canceled. Your plan will not renew at {{end_date}}',

    back: 'Back',
    forward: 'Complete',

    profile: 'Profile',

    name: 'Name',
    surname: 'Surname',
    email: 'Email',

    resetPassword: 'Reset Password',
    successfullyResetPassword: 'Successfully Reset Password',

    oldPassword: 'Old Password',
    newPassword: 'New Password',
    confirmPassword: 'Confirm New Password',

    invalidCode: 'Invalid Code',

    save: 'Save',

    logout: 'Logout',

    updateError: 'An error occurred during the update. please try again later.',
    updateSuccess: 'Successfully updated',

    contactUs: 'Contact Us',
    choosePlan: 'Choose Plan',
    monthly: 'Monthly',
    yearly: 'Yearly',
    monthlyBilled: '${{price}}/Month',
    yearlyBilled: 'Billed ${{price}}/Year',

    planControllerDescription: {
      templates: '50+ AI templates',
      languages_supported: '20+ languages supported',
      high_quality_text_generation: 'High quality text generation',
      image_generation: 'Image generation',
      fact_checking: 'Fact checking ',
      plagiarism_check: 'Plagiarism check',
      higher_quality_translation: 'Higher quality translation ',
      higher_resolution_images: 'Higher resolution images',
      onboarding_and_training_sessions: 'Onboarding and training sessions',
      dedicated_account_manager: 'Dedicated account manager',
      style_calibration: 'Style calibration',
      content_agency: 'Content agency'
    }
  },

  article: {
    add: 'Add',
    noInputEntered: 'No input entered to the editor. Please enter some prompt.',
    addWithLink: 'Add with Link',
    templates: 'All Templates',
    onboardingTitle: 'Create Your Own Custom Template',
    onboardingDescription:
      'Creating your own AI templates is now easier than ever before! All you have to do is upload text files and the Novus Writer will create your very own template.',
    onboardingButton: 'Let’s do it 🔮',

    instructionsTitle: '🔮 Create Your Custom Template',
    instructionsItemTitles: {
      1: 'Get Started',
      2: 'Upload a Document',
      3: 'Define Your Model',
      4: 'Template Preparation',
      5: 'Model Availability Notification',
      6: 'Select Your Custom Template',
      7: 'Dive In',
      8: `Iterate and Improve`,
      9: 'Combine Features',
      10: `Share Your Creations!`
    },
    instructionsItemDescriptions: {
      1: 'To create your own custom AI model, locate the "Custom Template" feature in the generative AI web application.',
      2: 'Drag and drop a file, or select the file to submit from your computer to begin creating your custom template.',
      3: 'Enter a unique name and brief explanation for your custom template to help you remember its purpose and usage.',
      4: 'Allow approximately 2 hours for your custom template to be processed. In the meantime, feel free to explore and use other features within the web application.',
      5: 'We will inform you when your custom template is ready to use. Keep an eye out for the notification!',
      6: 'Access your freshly created custom template from the left sidebar in the web application.',
      7: 'Start using your custom template by asking questions or providing a sentence as a prompt to generate insightful paragraphs from your AI model.',
      8: `Based on your experience, feel free to modify and re-upload your custom templates to enhance the generated content's relevance and quality.`,
      9: 'Experiment with integrating other features from the generative AI web application to maximize the potential of your custom template.',
      10: `Don't forget to share your unique custom templates with colleagues and friends who might benefit from leveraging your AI models!`
    },
    instructionButton: 'Dive in',

    resultTitle: 'Your custom template is being prepared!',
    resultItemTitles: {
      1: 'Template Preparation',
      2: 'Model Availability Notification'
    },
    resultItemDescriptions: {
      1: 'Allow approximately 10 minutes for your custom template to be processed. In the meantime, feel free to explore and use other features within the web application.',
      2: 'We will inform you when your custom template is ready to use. Keep an eye out for the notification!'
    },
    resultButton: 'Got it!',
    customAiOutputExampleLabel: 'Output Example',
    customAiOutputExampleDescription: 'What pain point did you solve for the customer?',
    customAiOutputExamplePlaceholder: 'e.g., bluetooth headphone, super cheap, easy to use',
    customAiTemplateDescriptionLabel: 'Template Description',
    customAiTemplateDescriptionDescription: 'What pain point did you solve for the customer?',
    customAiTemplateDescriptionPlaceholder: 'e.g., bluetooth headphone, super cheap, easy to use',
    deleteAllButton: 'Delete All Files',
    deleteAllTitle: 'Are you sure want to delete all the files?',
    deleteAllDescription: 'If you delete the files, all the data you saved will be permanently deleted.',

    customTemplateFilesUploaded: 'Files uploaded',
    customTemplateCreated: 'Custom is preparing now!',
    deleteSomeButton: 'Delete Selected File(s)',
    deleteSomeTitle: 'Are you sure want to delete the selected file(s)?',
    deleteSomeDescription: 'If you delete the file(s), all the data you saved will be permanently deleted.',
    customTemplatePreparingTitle: 'Your custom template is being prepared!',
    customTemplatePreparingDescription:
      'We will inform you when your custom template is ready to use. Keep an eye out for the notification!',
    customTemplateMaxFileSizeReached:
      'You have reached the total file size you can upload. Please delete some to proceed.',
    customAiAddedFilesPlaceholder: 'Select and Add Files',
    customAiAddedFilesDescription:
      'You need to select and add at least one file from My Files tab to create a custom template.',
    customTemplateFieldRequired: '*This field is required',
    customTemplateAddFileButton: 'Add',
    selectedFiles: 'Selected Files',

    myFiles: 'My Files',
    uploadNew: 'Upload New',

    inProgress: 'In Progress...',
    templatesSettings: 'Template Settings',
    currentTemplate: 'Current Template',
    otherTemplates: 'Other Templates',
    customTemplates: 'Custom Templates',
    beta: 'Beta',
    imageTemplates: 'Image Templates',
    placeholderImage: "Enter your dream image here and we'll make it come true.",
    placeholderText: "What do you want to write about? We'll help you.",

    shrinkPanel: 'Shrink Panel',
    openPanel: 'Open Panel',
    you: 'You',
    view: 'View',

    plagiarism: 'Plagiarism',
    generalSimilarity: 'General Similarity',
    someOfThose: 'Some of those',
    noPlagiarism: 'No plagiarism',

    upload: 'Upload',
    uploads: 'Uploads',

    download: 'Download',

    start: 'Start',
    goToHome: 'Go to Home',
    customTemplate: 'Custom Template',
    createTemplate: 'Create Template',
    trainingTemplate: 'Training template',
    autoMlImageStartInformation:
      'You can create your own visual template in Novus Writer. For this, it is sufficient to upload special images to the topics you want in certain formats. Remember, you will get the best results in 200 installs.',
    autoMlTextStartInformation:
      'Creating your own AI templates is now easier than ever before! All you have to do is upload text files and the Novus Writer will create your very own template.',
    autoMlEndInformation:
      'Novus Writer will process the documents for 1-3 hours. You will receive a notification e-mail to {{email}}, your registered mail in the system.',

    autoMlImageUploadInformation: 'Drag images in multiple jpg, png formats',
    autoMlTextUploadInformation: 'Drag and drop .txt or .docx files',
    or: 'or',
    addFile: 'Add File',
    fileLoading: 'File Loading...',
    noFiles: 'No saved files',
    uploadFile: 'Upload Files',

    gotIt: 'Got it!',
    cancel: 'Cancel',
    select: 'Select',
    deleteAll: 'Delete All',
    delete: 'Delete',

    deleteAllInformation: 'Are you sure you want to delete all the files?',

    templateName: 'Template Name',
    templateDescription: 'Template Description',

    againWrite: 'Write Again',

    regenerateSentence: 'Regenerate Sentence',
    regenerateSuccessInfo: 'Your sentence is being reconstructed..',
    regenerateErrorInfo: 'An error occurred while reconstructing your sentence. Please try again later.',

    templateParent: {
      '': 'Templates Box',
      Communications: 'Communications',
      UserTemplate: 'Custom Templates',
      Beta: 'Beta',
      'Writing Tools': '✏️ Writing Tools',
      'SEO Boosters': "🔍 SEO Booster's",
      'Email Providers': '✉️ Email Providers',
      'E-commerce': '🛒 E-commerce',
      'Video Builder': '📹 Video Builder',
      'Daily Use': '☀️ Daily Use',
      'Industrial Applications': '💼 Industrial Applications'
    },

    templateNames: {
      Suggestions: 'Novus Chat',
      Healthcare: 'Healthcare Long',
      'Healthcare 2': 'Healthcare Short',
      Intro: 'Intro',
      '2nd Grader Summarization': '2nd Grader Summarization',
      'Third-person converter': 'Third-person converter',
      'Ad Generation': 'Ad Generation',
      'Blog Outline / Ideas': 'Blog Outline / Ideas',
      'Grammar correction': 'Grammar Correction',
      'Create study notes': 'Create Study Notes',
      'Meeting Notes': 'Meeting Notes',
      'Paragraph Completion': 'Paragraph Completion',
      Email: 'Email',
      Summarize: 'Summarize',
      Rewrite: 'Rewrite',
      'Byte-Sized': 'Byte-Sized',
      'Media Content Gen': 'Media Content Gen',
      'Venti Article': 'Venti Article',
      'Mind Flow': 'Mind Flow',
      'Title Creator': 'Title Creator',
      Paraphrase: 'Paraphrase',
      'Detailed Suggestion': 'Suggestion',
      SEO: 'SEO',
      'Complete Blog Post': 'Complete Blog Post',
      'SEO Meta Title': 'SEO Meta Title',
      'Rephrase with Synonyms': 'Rephrase with Synonyms',
      'The Athletic - Lebron edition': 'The Athletic - Lebron edition',
      'NYT Style': 'NYT Style',
      'Novus Writing Assistant': 'Novus Writing Assistant',
      'Case Study': 'Case Study',
      'Elevator Pitch': 'Elevator Pitch',
      'Real Estate Listing': 'Real Estate Listing',
      Commands: 'Commands',
      'Video Description': 'Video Description',
      'Video Titles': 'Video Titles',
      'Video Script': 'Video Script',
      'Blog Outline & Ideas': 'Blog Outline & Ideas',
      'Seo Article': 'SEO Article',
      'Seo UGC': 'SEO UGC',
      'Seo Leadership': 'Leadership Blog Post',
      'Cold Email': 'Cold E-mail',
      'Sales Sequence': 'Sales Sequence',
      'Google Ads Description': 'Google Ads Description',
      'Google Ads Headline': 'Google Ads Headline',
      'Etsy Product Description': 'Etsy Product Description',
      'Instagram Market Post': 'Instagram Market Post',
      'Easy Response': 'Easy Response',
      'Pain Point Generator': 'Pain Point Generator',
      '1620': '1620',
      'Blog Post': 'Blog Post',

      // Image template names
      Dalle2: 'DALL·E 2',
      'Free-Form': 'Free-Form',
      'Human included realistic photos': 'Human Included Realistic Photos',
      'Portrait of people': 'Portrait of People',
      'Architecture & Landscape': 'Architecture & Landscape',
      'Comic Book Style': 'Dynamic Image',
      Scenery: 'Scenery',
      'Material Photography': 'Material Photography',
      Fashion: 'Fashion',
      Cyberpunk: 'Cyberpunk',
      'Asian Graphic Design': 'Asian Graphic Design ',
      'Poster design': 'Poster design',
      'Futuristic&Cyberpunk Style': 'Futuristic Style',
      'Animation&Manga': 'Animation & Manga',
      Drawing: 'Drawing',
      Cinematic: 'Cinematic',
      'Oil Painting': 'Oil Painting',
      'Social Media Post for Instagram': 'Social Media Post for Instagram',
      'Social Media Post for Twitter': 'Social Media Post for Twitter',
      'Social Media Post for Facebook': 'Social Media Post for Facebook'
    },

    form: {
      1620: {
        topic: {
          title: 'Topic',
          description: '',
          placeholder: "1620's values and company history, considering the industry insights"
        },

        tone: {
          title: 'Tone',
          description: '',
          placeholder: 'Professional'
        },

        direction: {
          title: 'Direction',
          description: '',
          placeholder: 'Topic-oriented'
        }
      },

      Healthcare: {
        keyword_1: {
          title: 'Keywords 1',
          description: '',
          placeholder: 'e.g., Robotic Surgery, Dermatology, etc. '
        },

        keyword_2: {
          title: 'Keywords 2',
          description: '',
          placeholder: 'e.g., Robotic Hernia Surgery, Dermatology Clinic, etc. '
        },

        keyword_3: {
          title: 'Keywords 3',
          description: '',
          placeholder: 'e.g., Skin, Hair, Bone Disease  etc. '
        }
      },

      'Healthcare 2': {
        keyword_1: {
          title: 'Keywords 1',
          description: '',
          placeholder: 'e.g., Robotic Surgery, Dermatology, etc. '
        },

        keyword_2: {
          title: 'Keywords 2',
          description: '',
          placeholder: 'e.g., Robotic Hernia Surgery, Dermatology Clinic, etc. '
        },

        keyword_3: {
          title: 'Keywords 3',
          description: '',
          placeholder: 'e.g., Skin, Hair, Bone Disease  etc. '
        }
      },

      'Blog Post': {
        main_keyword: {
          title: 'Main Keyword',
          description: '',
          placeholder: 'e.g., Taylor Swift, Singer, etc. '
        },

        sub_keyword: {
          title: 'Sub Keyword',
          description: '',
          placeholder: 'e.g., Era tour etc. '
        },

        sub_keyword_2: {
          title: 'Sub Keyword 2',
          description: '',
          placeholder: 'e.g., Economic Boost etc. '
        }
      },

      'Case Study': {
        company: {
          title: 'Company',
          description: 'What company are you writing this case study for?',
          placeholder: 'e.g., Koala Inc.'
        },
        customer: {
          title: 'Customer',
          description: 'Which customer are you spotlighting?',
          placeholder: 'e.g., Panda Inc.'
        },
        challenge: {
          title: 'Challenge',
          description: 'What is the challenge?',
          placeholder:
            'e.g.,  Panda needs a new UI/UX for their SaaS product to increase the efficiency of onboarding for their customers.'
        },
        solution: {
          title: 'Solution',
          description: 'Example solution',
          placeholder:
            'Koala provided consultancy, a design process and provided the onboarding-oriented design with its development team.'
        },
        results: {
          title: 'Result',
          description: 'Target result',
          placeholder: 'Panda decreased the onboarding time from 2 hours to 15 minutes.'
        }
      },

      'Elevator Pitch': {
        product_name: {
          title: 'Product Name',
          description: 'Product name and category',
          placeholder: 'Gmail'
        },
        problem: {
          title: 'Problem',
          description: 'What is the problem at hand?',
          placeholder: 'Limited human access to the data.'
        },
        solution: {
          title: 'Solution',
          description: 'What is the proposed solution?',
          placeholder: 'Easy to control filing system for emails'
        },
        key_benefit: {
          title: 'Key Benefit',
          description: 'Key benefit of your solution',
          placeholder: 'Making users able to check and create your emails with a click.'
        }
      },

      'Real Estate Listing': {
        information: {
          title: 'Information',
          description: 'Information about the home for listing',
          placeholder: '4 beds, 3 baths, 1.4 acre lot. large garden, fruit trees. Next to TD Arena.'
        },
        pushing_details: {
          title: 'Pushing Details',
          description: 'Highlights to emphasize',
          placeholder: 'Riverside, allowing to watch games from home.'
        },
        tone: {
          title: 'Tone',
          description: 'Tone',
          placeholder: 'Professional'
        }
      },

      Commands: {
        command: {
          title: 'Command',
          description: 'Command to give',
          placeholder: "Act like a master chess player and comment on Queen's Gambit series last game"
        },
        additional_details: {
          title: 'Additional Details',
          description: 'Additional details to add',
          placeholder: "Beth's two favorite openings are the Queen's Gambit and the Sicilian Defense"
        },
        tone: {
          title: 'Tone',
          description: 'Tone',
          placeholder: 'Informative'
        }
      },

      'Video Description': {
        video_topic: {
          title: 'Video Topic',
          description: 'Topic',
          placeholder: 'Slime challenge and Iphone giveaway'
        },
        keywords: {
          title: 'Keywords',
          description: 'Keywords to consider for ranking',
          placeholder: "Beth's two favorite openings are the Queen's Gambit and the Sicilian Defense"
        },
        tone: {
          title: 'Tone',
          description: 'Tone',
          placeholder: 'Professional'
        }
      },

      'Video Titles': {
        video_topic: {
          title: 'Video Topic',
          description: 'Topic',
          placeholder: 'Best places to eat in Boston'
        },
        keywords: {
          title: 'Keywords',
          description: 'Keywords to consider for ranking',
          placeholder: 'Boston, Oyster'
        },
        tone: {
          title: 'Tone',
          description: 'Tone',
          placeholder: 'Informative'
        }
      },

      'Video Script': {
        video_topic: {
          title: 'Video Title',
          description: 'Title',
          placeholder: 'Largest buildings on Earth'
        },
        tone: {
          title: 'Tone',
          description: 'Tone',
          placeholder: 'Informative'
        }
      },

      'Ad Generation': {
        company_definition: {
          title: 'Company Definition',
          description: 'The company definition to be used for ad',
          placeholder: 'Novus writer empowers businesses with custom AI'
        }
      },

      'Blog Outline & Ideas': {
        blog_topic: {
          title: 'Blog Topic',
          description: 'The topic you would like to write about',
          placeholder: 'Boston sea foods'
        },
        tone: {
          title: 'Tone',
          description: '',
          placeholder: 'Conversational, Informative'
        }
      },

      'Instagram Market Product': {
        product_name: {
          title: 'Product Name',
          description: '',
          placeholder: 'Jelly Snake'
        },
        written_by: {
          title: 'Written by',
          description: '',
          placeholder: 'Nietzsche'
        },
        product_description: {
          title: 'Product Info',
          description: '',
          placeholder: 'Gelatin snakes for pets to play and chew'
        }
      },

      'SEO Article': {
        topic: {
          title: 'Topic',
          description: 'The topic you would like to write about',
          placeholder: 'Health care tourism'
        },
        keywords: {
          title: 'Keywords',
          description: '',
          placeholder: 'Hair transplant, Istanbul, Best'
        }
      },

      'Seo Article': {
        topic: {
          title: 'Topic',
          description: 'The topic you would like to write about',
          placeholder: 'Health care tourism'
        },
        keywords: {
          title: 'Keywords',
          description: '',
          placeholder: 'Hair transplant, Istanbul, Best'
        }
      },

      'Seo UGC': {
        headline: {
          title: 'Headline',
          description: 'The main point',
          placeholder: 'Youtube is the next market'
        },
        keywords: {
          title: 'Keywords',
          description: '',
          placeholder: 'UGC, Youtube, Video'
        },
        tone: {
          title: 'Tone',
          description: '',
          placeholder: 'Funny'
        }
      },

      'Seo Leadership': {
        headline: {
          title: 'Headline',
          description: 'The main point',
          placeholder: 'Mastering the risk'
        },
        keywords: {
          title: 'Keywords',
          description: '',
          placeholder: 'Leadership, risk management'
        },
        tone: { title: 'Tone', description: '', placeholder: 'Humorous' }
      },

      'Cold Email': {
        product: {
          title: 'Your Product',
          description: 'What is your product',
          placeholder: 'Internet cafe software that helps cafe owners manage their cafe sessions'
        },
        product_name: {
          title: 'Product Name',
          description: '',
          placeholder: 'Akin soft'
        },
        context: {
          title: 'Context',
          description: 'Context to include in the email',
          placeholder: 'We have met in the vegas convention'
        },
        tone: {
          title: 'Tone',
          description: '',
          placeholder: 'Conversational, Informative'
        }
      },

      'Sales Sequence': {
        company: {
          title: 'Company',
          description: '',
          placeholder:
            'Venture Lane is a co-working space for businesses who are looking for a convenient office in a good location with affordable costs'
        },
        tone: {
          title: 'Tone',
          description: 'Tone',
          placeholder: 'Professional'
        }
      },

      'Google Ads Description': {
        product_name: {
          title: 'Product Name',
          description: '',
          placeholder: 'Jelly Snake'
        },
        product_description: {
          title: 'Product Description',
          description: '',
          placeholder: 'Gelatin snakes for pets to play and chew.'
        },
        tone: {
          title: 'Tone',
          description: '',
          placeholder: 'Humorous'
        },
        example: {
          title: 'Example',
          description: '',
          placeholder: 'No Plastic, Not another to be cast aside pet toy.'
        }
      },

      'Google Ads Headline': {
        product_name: {
          title: 'Product Name',
          description: '',
          placeholder: 'Jelly Snake'
        },
        product_description: {
          title: 'Product Description',
          description: '',
          placeholder: 'Gelatin snakes for pets to play and chew.'
        },
        tone: {
          title: 'Tone',
          description: '',
          placeholder: 'Humorous'
        },
        example: {
          title: 'Example',
          description: '',
          placeholder: 'No Plastic, Not another to be cast aside pet toy.'
        }
      },

      'Etsy Product Description': {
        product_name: {
          title: 'Product Name',
          description: '',
          placeholder: 'Handmade suitcase'
        },
        key_benefits: {
          title: 'Key Benefits',
          description: '',
          placeholder: 'Sustainable materials, Antimicrobial, authentic'
        },
        tone: {
          title: 'Tone',
          description: '',
          placeholder: 'joyful'
        }
      },

      'Instagram Market Post': {
        product_name: {
          title: 'Product Name',
          description: '',
          placeholder: 'Jelly Snake'
        },
        written_by: {
          title: 'Written by',
          description: '',
          placeholder: 'Nietzsche'
        },
        product_description: {
          title: 'Product Info',
          description: '',
          placeholder: 'Gelatin snakes for pets to play and chew'
        }
      },

      'Easy Response': {
        received_email: {
          title: 'Received Mail',
          description: '',
          placeholder:
            'Is it possible that software development team number 2, the machine learning department, will be able to complete the task we reported yesterday by tomorrow?'
        },
        response_message: {
          title: 'Response Message',
          description: '',
          placeholder: "No, we can't do that. You should inform us earlier."
        },
        tone: {
          title: 'Tone',
          description: '',
          placeholder: 'conversational, 25% spartan'
        }
      },

      'Pain Point Generator': {
        company: {
          title: 'Company',
          description: '',
          placeholder: 'Pigastro'
        },
        target: {
          title: 'Target',
          description: '',
          placeholder: 'Students and universities'
        },
        value_props: {
          title: 'Value Propositions',
          description: '',
          placeholder: 'Cheap and high quality food'
        },
        objectives: {
          title: 'Objectives',
          description: '',
          placeholder: 'Reaching out to more campuses'
        }
      },

      '1620 Trained': {
        topic: {
          title: 'Topic',
          description: '',
          placeholder: "1620's values and company history, considering the industry insights"
        },
        tone: {
          title: 'Tone',
          description: '',
          placeholder: 'Professional'
        },
        direction: {
          title: 'Direction',
          description: '',
          placeholder: 'Topic-oriented'
        }
      }
    },

    factAndOutputSame: 'Let the Fact Check language and the output language be the same',
    suggestionTextLength: 'Output text length',

    numberOfImage: 'Number of Image',
    aestheticRating: 'Aesthetic Rating',
    aestheticWeight: 'Aesthetic Weight',
    guidanceScale: 'Guidance Scale',
    promptStrength: 'Prompt Strength',
    relevance: 'relevance',
    seed: 'Seed',
    trialSteps: 'Trial Steps',

    homepage: 'Homepage',
    projectName: 'Project Name',
    template: 'Template',

    saveFeedback: 'All your changes are being saved',
    unSaveFeedback: 'The article could not be saved',

    askNovus: 'Ask Novus Writer a question',

    goToEditor: 'Go to Editor',
    goToInput: 'Go to Input',

    uploadImage: 'Upload Image',
    drawImage: 'Draw Image',

    text: 'Text',
    image: 'Image',
    'Free-form': {},

    textGeneratorPlaceholder: 'Input some content about the topic you want to create',
    imageGeneratorPlaceholder: 'Describe your dream image ...',
    editorPlaceholder: 'Start creating your content by copying and pasting the texts you have produced here.',

    title: 'Title',
    optional: 'optional',

    create: 'Create',
    save: 'Save',

    noGenerationTop: 'View new content here',
    noGenerationBottom: 'by entering text in the sidebar',

    copySuccessful: 'Copied successfully',
    downloadSuccesful: 'Downloaded successfully',
    deleteBookmarkSuccessful: 'Bookmark deleted successfully',
    bookmarkSuccessful: 'Bookmark added successfully',

    copy: 'Copy',
    removeFromBookmarks: 'Remove from Bookmarks',

    bookmarks: 'Bookmarks',

    showContentSource: 'Show content source >>',

    tab: 'Tab',

    languageSettings: 'Language Settings',
    inputLanguage: 'Input Language',
    outputLanguage: 'Output Language',

    proofRead: 'Proofread',

    factCheckIsLoading: 'Checking the correctness of sentences....',
    notFoundSaveEditor: 'No saved editorial was found.'
  },
  common: {
    usageLimitReached: 'Your usage limit has been reached or your plan is expired!',

    getTemplatesError: 'Could not get text templates. Please try again later!',
    getTemplatesDalleError: 'Could not get image templates. Please try again later!',
    saveArticleError: 'Could not save article. Please try again later!',
    getSavedAiTextGeneratinsError: 'Could not get saved text generations. Please try again later!',
    getSavedAiImageGeneratinsError: 'Could not get saved image generations. Please try again later!',
    newAiTextGenerationError: 'Could not generate new text suggestions. Please try again later!',
    newAiImageGenerationError: 'Could not generate new image suggestions. Please try again later!',
    getEditorStateError: 'Could not get editor save. Please try again later!',
    saveEditorStateError: 'Could not save editor. Please try again later!',

    copyError: 'Could not copy. Please try again later!',
    downloadError: 'Could not download. Please try again later!',
    bookmarkError: 'Could not bookmark. Please try again later!',
    removeBookmarkError: 'Could not remove from bookmarks. Please try again later!',
    factCheckError: 'Fact check could not be done. Please try again later!',
    factCheckLowPlanError: 'To use fact check, you must upgrade your plan.',
    factCheckLimitError: 'Fact check usage limit has expired.',

    somethingsWrongError: 'Something is wrong. Please try again later!',
    contentNotEmpty: 'Content cannot be empty!'
  },
  admin: {
    upgrade: 'Upgrade',
    downgrade: 'Downgrade'
  }
};

export default translations;
