import { produce } from 'immer';
import { Action, Thunk } from 'redux/store';
import {
  AITemplate,
  ArticleFormType,
  ArticleImageGenerationSettings,
  ArticleTextGenerationSettings,
  ImageContents,
  TextContents
} from 'utils/types';
import {
  APPROACH_OPTIONS,
  AUDIENCE_OPTIONS,
  DEFAULT_ARTICLE_IMAGE_GENERATION_SETTINGS,
  DEFAULT_ARTICLE_TEXT_GENERATION_SETTINGS,
  PLATFORM_OPTIONS
} from 'config/constants';
import { MergeAction, getEntities, getEntity, mergeEntities } from './entity';
import { cloneDeep, head } from 'lodash';
import { toneOptionsGetter } from 'utils/helpers/articleHelper';
import { hasApproachOptionsGetter } from 'utils/helpers/articleHelper';
import { updateArticle } from './article';
import { RawDraftContentState } from 'draft-js';
import { apiService } from '../service/service';

type State = {
  selectedTextTemplateId: Record<string, string>;
  selectedImageTemplateId: Record<string, string>;
  selectedTabId: Record<string, string>;
  generationType: Record<string, ArticleFormType>;
  inputType: 'editor' | 'form';
  textGenerationSettings: Omit<ArticleTextGenerationSettings, 'templates'>;
  imageGenerationSettings: Omit<ArticleImageGenerationSettings, 'templates'>;
};

export const initialState: State = {
  selectedTextTemplateId: {},
  selectedImageTemplateId: {},
  selectedTabId: {},
  generationType: {},
  inputType: 'form',
  textGenerationSettings: DEFAULT_ARTICLE_TEXT_GENERATION_SETTINGS,
  imageGenerationSettings: DEFAULT_ARTICLE_IMAGE_GENERATION_SETTINGS
};

type ActionType =
  | { type: 'editor/setSelectedTextTemplateId'; templateId: string; articleId: string }
  | { type: 'editor/setSelectedImageTemplateId'; templateId: string; articleId: string }
  | { type: 'editor/setSelectedTabId'; tabId: string; articleId: string }
  | { type: 'editor/setArticleGenerationType'; articleId: string; generationType: ArticleFormType }
  | { type: 'editor/setArticleInputType'; inputType: 'editor' | 'form' }
  | { type: 'editor/setSelectedTabId'; tabId: string; articleId: string }
  | { type: 'editor/setTextGenerationSettings'; settings: Partial<Omit<ArticleTextGenerationSettings, 'templates'>> }
  | { type: 'editor/setImageGenerationSettings'; settings: Partial<Omit<ArticleImageGenerationSettings, 'templates'>> }
  | { type: 'editor/replaceTextGenerationSettings'; settings: Omit<ArticleTextGenerationSettings, 'templates'> }
  | { type: 'editor/replaceImageGenerationSettings'; settings: Omit<ArticleImageGenerationSettings, 'templates'> }
  | MergeAction
  | Action<'me/logout'>;

export const editorReducer = (state = initialState, action: ActionType): State => {
  switch (action.type) {
    case 'editor/setArticleInputType':
      return produce(state, (draft) => {
        draft.inputType = action.inputType;
      });

    case 'editor/setSelectedTextTemplateId':
      return produce(state, (draft) => {
        draft.selectedTextTemplateId[action.articleId] = action.templateId;
      });

    case 'editor/setSelectedTabId':
      return produce(state, (draft) => {
        draft.selectedTabId[action.articleId] = action.tabId;
      });

    case 'editor/setSelectedImageTemplateId':
      return produce(state, (draft) => {
        draft.selectedImageTemplateId[action.articleId] = action.templateId;
      });

    case 'editor/setArticleGenerationType':
      return produce(state, (draft) => {
        draft.generationType[action.articleId] = action.generationType;
      });

    case 'editor/setTextGenerationSettings':
      return produce(state, (draft) => {
        draft.textGenerationSettings = { ...draft.textGenerationSettings, ...action.settings };
      });

    case 'editor/setImageGenerationSettings':
      return produce(state, (draft) => {
        draft.imageGenerationSettings = { ...draft.imageGenerationSettings, ...action.settings };
      });

    case 'editor/replaceTextGenerationSettings':
      return produce(state, (draft) => {
        draft.textGenerationSettings = action.settings;
      });

    case 'editor/replaceImageGenerationSettings':
      return produce(state, (draft) => {
        draft.imageGenerationSettings = action.settings;
      });

    case 'me/logout':
      return initialState;

    default:
      return state;
  }
};

// @actions

export const setSelectedTextTemplateId =
  (articleId: string, templateId: string, saveArticle: boolean = false): Thunk<ActionType> =>
  (dispatch, getState) => {
    const template = getEntity(getState().entity, 'template', templateId);
    const article = getEntity(getState().entity, 'article', articleId);
    const aiTemplates = getEntities(getState().entity, 'aiTemplate');
    if (!template) return;

    const settings = produce(cloneDeep(getState().editor.textGenerationSettings), (draft) => {
      // Configure settings based on template
      if (!template.render?.includes('template')) delete draft.aiTemplates;
      else if (!draft.aiTemplates) draft.aiTemplates = head(aiTemplates);

      // Tone
      if (!template.render?.includes('tone')) delete draft.tone;
      else if (!draft.tone) draft.tone = head(toneOptionsGetter(template.name))?.value;

      // Platform
      if (!template.render?.includes('platform')) delete draft.platform;
      else if (!draft.platform) draft.platform = head(PLATFORM_OPTIONS)?.value;

      /// Audience
      if (!template.render?.includes('audience')) delete draft.audience;
      else if (!draft.audience) draft.audience = head(AUDIENCE_OPTIONS)?.value;

      // Approach
      if (!hasApproachOptionsGetter(template.name)) delete draft.approach;
      else if (!draft.approach) draft.approach = head(APPROACH_OPTIONS)?.value;
    });

    if (article && saveArticle) {
      dispatch(
        updateArticle(articleId, {
          text_prompt: article?.text_prompt,
          selected_template_id: templateId,
          text_settings: {
            ...settings,
            template_id: templateId,
            text_length: settings.textLength,
            ai_templates_id: settings.aiTemplates?.id ?? ''
          }
        })
      );
    }
    dispatch({ type: 'editor/replaceTextGenerationSettings', settings });
    dispatch({ type: 'editor/setSelectedTextTemplateId', templateId, articleId });
  };

export const setSelectedImageTemplateId =
  (articleId: string, templateId: string, saveArticle: boolean = false): Thunk<ActionType> =>
  (dispatch, getState) => {
    const settings = DEFAULT_ARTICLE_IMAGE_GENERATION_SETTINGS;
    const article = getEntity(getState().entity, 'article', articleId);

    if (article && saveArticle) {
      dispatch(
        updateArticle(articleId, {
          image_prompt: article?.image_prompt,
          selected_template_id: templateId,
          image_settings: DEFAULT_ARTICLE_IMAGE_GENERATION_SETTINGS
        })
      );
    }
    dispatch({ type: 'editor/replaceImageGenerationSettings', settings });
    dispatch({ type: 'editor/setSelectedImageTemplateId', templateId, articleId });
  };

export const setArticleGenerationType = (articleId: string, generationType: ArticleFormType): ActionType => ({
  type: 'editor/setArticleGenerationType',
  articleId,
  generationType
});

export const setArticleInputType = (inputType: 'editor' | 'form'): ActionType => ({
  type: 'editor/setArticleInputType',
  inputType
});

export const setSelectedTabId = (articleId: string, tabId: string): ActionType => ({
  type: 'editor/setSelectedTabId',
  articleId,
  tabId
});

export const setTextGenerationSettings = (
  settings: Partial<Omit<ArticleTextGenerationSettings, 'templates'>>
): ActionType => ({
  type: 'editor/setTextGenerationSettings',
  settings
});

export const setImageGenerationSettings = (
  settings: Partial<Omit<ArticleImageGenerationSettings, 'templates'>>
): ActionType => ({
  type: 'editor/setImageGenerationSettings',
  settings
});

export const replaceTextGenerationSettings = (
  settings: Omit<ArticleTextGenerationSettings, 'templates'>
): ActionType => ({
  type: 'editor/replaceTextGenerationSettings',
  settings
});

export const replaceImageGenerationSettings = (
  settings: Omit<ArticleImageGenerationSettings, 'templates'>
): ActionType => ({
  type: 'editor/replaceImageGenerationSettings',
  settings
});

export const mergeTextGenerationSetting = (aiTemplate: AITemplate): ActionType => ({
  type: 'editor/setTextGenerationSettings',
  settings: {
    aiTemplates: aiTemplate,
    temperature: aiTemplate.data.temperature / 100,
    presence_penalty: aiTemplate.data.presence_penalty / 100,
    frequency_penalty: aiTemplate.data.frequency_penalty / 100
  }
});

export const setArticleTextContent =
  (articleId: string, content: TextContents): Thunk<ActionType> =>
  (dispatch, getState) => {
    const article = getEntity(getState().entity, 'article', articleId);
    if (article) dispatch(mergeEntities({ article: [{ ...article, text_prompt: content }] }));
  };

export const setArticleImageContent =
  (articleId: string, content: ImageContents): Thunk<ActionType> =>
  (dispatch, getState) => {
    const article = getEntity(getState().entity, 'article', articleId);
    if (article) dispatch(mergeEntities({ article: [{ ...article, image_prompt: content }] }));
  };

export const saveEditorState =
  (articleId: string, state: RawDraftContentState): Thunk<ActionType> =>
  async (dispatch, getState) => {
    const article = getEntity(getState().entity, 'article', articleId);
    if (!article) return;

    return apiService
      .saveEditor(articleId, state)
      .then(() => dispatch(mergeEntities({ article: [{ ...article, editor_content: state }] })))
      .observe('save_editor_state', dispatch)
      .catch((error) => console.log('error saving editor state', error));
  };
