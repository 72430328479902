import { ReactComponent as SearchLens } from 'assets/img/icons/search_lens.svg';
import { Col, FlexCol, Label } from 'features/common/Styles';
import { head } from 'lodash';
import { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getEntities, getEntity } from 'redux/entity';
import styled from 'styled-components';
import { retrieveTemplateIcon, retrieveTemplateName } from 'utils/helpers/articleHelper';
import { useSelect } from 'utils/hooks/useSelect';
import { Template } from 'utils/types';
import { ArticleDrawerTemplateListItem } from './ArticleDrawerTemplateListItem';

export const ArticleDrawerImageTemplates = memo<{
  articleId: string;
  onTemplateClick: (template: Template) => void;
}>(({ articleId, onTemplateClick }) => {
  const { t } = useTranslation(['article', 'common']);

  const templates = useSelect(({ entity }) => getEntities(entity, 'template', ({ type }) => type === 'image'));
  const [query, setQuery] = useState<string>('');

  const selectedTemplate = useSelect(({ editor, entity }) => {
    const templateId = editor.selectedImageTemplateId[articleId];
    const firstTemplate = head(getEntities(entity, 'template'));
    const template = getEntity(entity, 'template', templateId);
    return template || firstTemplate;
  });

  const filteredTemplates = useMemo(() => {
    if (!query) return templates;
    return templates.filter((template) =>
      t(`templateNames.${template.name}`).toLowerCase().includes(query.toLowerCase())
    );
  }, [query, templates]);

  // @handlers

  const handleSearchQueryChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  }, []);

  // @render

  return (
    <FlexCol>
      <Col style={{ paddingTop: 28, alignItems: 'center', minWidth: 230 }}>
        <Col style={{ alignItems: 'center' }}>
          <Title>{t('currentTemplate')}</Title>
          <Label style={{ fontSize: 14, color: 'white', marginTop: 2, minWidth: 190, maxWidth: 190 }}>
            {`${retrieveTemplateIcon(selectedTemplate)} ${retrieveTemplateName(selectedTemplate, t)}`}
          </Label>
        </Col>
      </Col>

      <FlexCol style={{ paddingLeft: 20, paddingRight: 20 }}>
        <SearchInputContainer>
          <span style={{ marginLeft: 10 }}>
            <SearchLens />
          </span>
          <SearchInput placeholder={t('home:search')} onChange={handleSearchQueryChange} value={query} />
        </SearchInputContainer>

        {filteredTemplates.map((template) => (
          <ArticleDrawerTemplateListItem
            key={template.id}
            template={template}
            isSelected={template.id === selectedTemplate?.id}
            isLastItem={false}
            removePadding
            onTemplateClick={onTemplateClick}
          />
        ))}
      </FlexCol>
    </FlexCol>
  );
});

const Title = styled(Label)`
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  flex: 1;
  color: ${({ theme }) => theme.colors.white};
`;

const SearchInputContainer = styled.div`
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  margin-top: 20px;
  margin-bottom: 10px !important;
`;

const SearchInput = styled.input`
  background: transparent;
  border: none;
  outline: none;
  color: ${({ theme }) => theme.colors.white};
  margin-left: 10px;
  padding: 10px 0px;

  &::placeholder {
    color: ${({ theme }) => theme.colors.white};
    opacity: 0.3;
  }
`;
