import { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useGetPlansQuery } from 'redux/account/api';
import styled from 'styled-components';
import { PlanType } from 'utils/types';
import { AllPlanControlParameter } from 'utils/constants';
import { ChoosePlanType } from './ChoosePlanType';
import { PlanCost } from './PlanCost';
import { PlanHeader } from './PlanHeader';
import { PlanListItem } from './PlanListItem';
import { PlanListSkeleton } from './PlanListSkeleton';
import { arrayMove } from 'utils/helpers/articleHelper';

export const Plans = memo<{
  onSelectPlan: (planName: PlanType) => void;
}>(({ onSelectPlan }) => {
  const { t } = useTranslation('account');
  const router = useHistory();

  const { data: savedPlans, isSuccess: getPlansIsSuccess } = useGetPlansQuery();

  const [planType, setPlanType] = useState<boolean>(true);

  // @handlers

  const handlePlanSelect = useCallback(() => {
    setPlanType(!planType);
  }, [planType, setPlanType]);

  const setPlan = useCallback(
    (planId: string) => () => {
      savedPlans &&
        savedPlans.forEach((item: PlanType) => {
          if (item.id === planId) {
            item.name !== 'enterprise' ? onSelectPlan(item) : router.push('/#contact-us');
          }
        });
    },
    [onSelectPlan, router, savedPlans]
  );

  const getPlans = useCallback(() => {
    if (!savedPlans) return [];
    let plans: PlanType[] = [];
    if (planType) {
      plans = savedPlans
        .filter(({ charge_interval }) => charge_interval !== 'yearly')
        .sort((a, b) => a.price - b.price);
    } else {
      plans = savedPlans
        .filter(({ charge_interval }) => charge_interval !== 'monthly')
        .sort((a, b) => a.price - b.price);
    }

    plans = arrayMove<PlanType>(plans, 0, plans.length - 1);

    return plans;
  }, [planType, savedPlans]);

  // @render

  return (
    <Container>
      {getPlansIsSuccess ? (
        <>
          <PlanTypeContainer>
            <ChoosePlanType type={planType} setType={handlePlanSelect} />
          </PlanTypeContainer>
          <PlansTable>
            <PlanContainer>
              <PlanControllerDescriptionContainer>
                {AllPlanControlParameter.map((item: any, key: number) => (
                  <PlanDescItem key={key}>{t(`planControllerDescription.${item}`)}</PlanDescItem>
                ))}
              </PlanControllerDescriptionContainer>
              {getPlans().map((plan: PlanType, index: number) => (
                <PlanFeatures key={index}>
                  <PlanHeader plan={plan} bgColor={index} />
                  <PlanCost planType={planType} plan={plan} />
                  <PlansTableList>
                    <PlanListItem key={index} item={plan.pros} />
                  </PlansTableList>
                  <PlansTableFooter>
                    <PlanFooterItem>
                      <PlanButton onClick={setPlan(plan.id)} background={index}>
                        {t('choosePlan')}
                      </PlanButton>
                    </PlanFooterItem>
                  </PlansTableFooter>
                </PlanFeatures>
              ))}
            </PlanContainer>
          </PlansTable>
        </>
      ) : (
        <PlanListSkeleton count={3} />
      )}
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PlanContainer = styled.div`
  display: flex;
  justify-content: center;

  & > :last-child > :not(:first-child) {
    border-right: 1px solid #cdd1de;
  }
`;

const PlanFeatures = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 15%;
`;

const PlanTypeContainer = styled.div`
  margin-top: 14px;
  margin-bottom: 20px;
`;

const PlansTable = styled.div`
  width: 100%;
  margin-bottom: 5%;
`;

const PlansTableList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > div > :last-child {
    border-bottom: 1px solid #cdd1de;
  }
`;

const PlansTableFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const PlanFooterItem = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-bottom: 1px solid #cdd1de;
  border-left: 1px solid #cdd1de;
  border-radius: 0 !important;
`;

const PlanControllerDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > :last-child {
    border-bottom: 1px solid #cdd1de;
  }
`;

const PlanDescItem = styled.div`
  width: 100%;
  transform: translateY(36px);
  text-overflow: ellipsis;

  padding: 20px;
  border-left: 1px solid #cdd1de;
  border-top: 1px solid #cdd1de;

  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;

  white-space: nowrap;
  background: ${({ theme }) => theme.colors.white};
`;

const PlanButton = styled.button<{ background: number }>`
  width: 100%;
  text-align: center;
  color: white;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 14px;
  padding: 14px 10px;
  border-radius: 4px;
  cursor: pointer;

  background-color: ${({ background }) => {
    return background === 0 ? '#66759C' : background === 1 ? '#5C856F' : background === 2 ? '#431075' : '#431075';
  }};

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }
`;
