import { memo } from 'react';
import styled from 'styled-components';
import { PlanType } from 'utils/types';

export const PlanHeader = memo<{
  plan: PlanType;
  bgColor: number;
}>(({ plan, bgColor }) => {
  // @render

  return (
    <Container>
      <Header>
        <Title bgColor={bgColor}>{plan.name}</Title>
      </Header>
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const Header = styled.div`
  width: 100%;
`;

const Title = styled.h3<{ bgColor: number }>`
  padding: 16px 2px 14px 16px;
  margin: 0;
  text-align: center;
  color: white;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  background-color: ${({ bgColor }) => {
    return bgColor === 0 ? '#66759C' : bgColor === 1 ? '#5C856F' : bgColor === 2 ? '#431075' : '#431075';
  }};
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`;
