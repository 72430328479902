import AppButton from 'features/common/AppButton';
import { UserAvatar } from 'features/common/UserAvatar';
import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logoutHandle } from 'redux/auth/slice';
import styled from 'styled-components';
import { useSelect } from 'utils/hooks/useSelect';

export const AccountPageContainer = memo<{
  children: React.ReactNode;
  activeStep: string;
}>(({ children, activeStep }) => {
  const history = useHistory();
  const { t } = useTranslation('account');
  const dispatch = useDispatch();

  const user = useSelect(({ me }) => me.user);

  // @handlers

  const replace = useCallback(
    (state: string) => () => {
      history.replace(state);
    },
    [history]
  );

  const logout = useCallback(() => {
    user && dispatch(logoutHandle({ userId: user.id, email: user.email }));
  }, [dispatch, user]);

  // @render

  return (
    <Container>
      <TopContainer>
        <Header>
          <BackToHomePage text={t('homepage')} onClick={replace('/')} />
          <LogoutButton text={t('logout')} variant="link" onClick={logout} />
        </Header>

        <TopActions>
          <StyledUserAvatar size="large" />
          <UserName>
            {user?.first_name} {user?.last_name}
          </UserName>

          <AccountActions>
            <AccountStepButton
              text={t('personalInformation')}
              active={activeStep === '/personal-information'}
              onClick={replace('/account/personal-information')}
            />
            <AccountStepButton
              text={t('passwordInformation')}
              active={activeStep === '/password-information'}
              onClick={replace('/account/password-information')}
            />
            <AccountStepButton
              text={t('upgradePlan')}
              active={activeStep === '/upgrade-plan'}
              onClick={replace('/account/upgrade-plan')}
            />
            <AccountStepButton
              text={t('subscription')}
              active={activeStep === '/subscription'}
              onClick={replace('/account/subscription')}
            />
          </AccountActions>
        </TopActions>
      </TopContainer>
      <BottomContainer>{children}</BottomContainer>
    </Container>
  );
});

//#region Header styles

const Container = styled.div`
  height: 100%;

  background-color: ${({ theme }) => theme.colors.gray[20]};
`;

const TopContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.purple[100]};
  display: flex;
  flex-direction: column;

  height: 289px;
`;

const Header = styled.div`
  background-color: ${({ theme }) => theme.colors.purple[120]};
  padding: 8px 70px;

  display: flex;
  justify-content: flex-end;
`;

const BackToHomePage = styled(AppButton)`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.purple[100]};
  padding: 8px 20px;

  font-family: Nunito Sans;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;

  &:hover {
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

const LogoutButton = styled(AppButton)`
  font-family: Nunito Sans;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;

  margin-left: 28px;

  background-color: transparent;
  color: ${({ theme }) => theme.colors.white};

  &:hover {
    color: red;
  }
`;

const TopActions = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  background-color: ${({ theme }) => theme.colors.purple[100]};
`;

const AccountStepButton = styled(AppButton).attrs((props: { active: boolean }) => ({
  active: props.active
}))`
  background-color: ${({ theme }) => theme.colors.purple[90]};
  color: ${({ theme }) => theme.colors.white};
  border: 1px solid
    ${({ theme, active }) => {
      if (active) {
        return theme.colors.white;
      } else {
        return theme.colors.purple[50];
      }
    }};

  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  padding: 13px 50px;

  &:active {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.purple[100]};
  }
`;

const StyledUserAvatar = styled(UserAvatar)`
  margin-top: 32px;
`;

const UserName = styled.div`
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 28px;
  margin-top: 16px;
`;

const AccountActions = styled.div`
  display: flex;
  margin-bottom: 30px;
  & :not(:last-child) {
    margin-right: 20px;
  }
`;

const BottomContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[20]};
`;
