const translations = {
  verifySignUp: {
    verifying: 'Doğrulanıyor...'
  },
  welcome: {
    //new
    loginToAccount: 'Hesabınıza giriş yapın',

    email: 'E-Posta Adresi',
    password: 'Parola',
    submit: 'Gönder',

    forgotPassword: 'Parolamı Unuttum',
    passwordExpired: 'Parola Süresi Doldu',
    passwordExpiredDescription:
      'Parolanızı güvenlik nedenleriyle değiştirmeniz gerekiyor! Lütfen devam etmek için lütfen mailinizi kontrol edin.',
    resetPassword: 'Parolayı Sıfırla',
    okay: 'Tamam',

    successForgotPassword: 'Başarılı! Lütfen parola sıfırlama bağlantısı için e-postanızı kontrol edin',
    errorForgotPassword: 'Hata! Lütfen tekrar deneyin',
    emptyForgotPassword: 'Lütfen e-posta adresinizi girin',
    signUpMessage: 'Hesabın Yok mu? Kayıt Ol',

    signIn: 'Giriş Yap',

    //old
    labels: {
      firstName: 'Ad',
      lastName: 'Soyad',
      email: 'E-Posta Adresi',
      password: 'Parola',
      passwordAgain: 'Parola (tekrar)',
      keepMeLogged: 'Beni Hatırla',
      forgotPassword: 'Parolamı Unuttum',
      signUpMessage: 'Hesabın Yok mu? Kayıt Ol',
      novusCode: 'Novus Doğrulama Kodu',
      submit: 'Gönder',
      goBack: '← Giriş Sayfasına Dön',
      signUp: 'Kayıt Ol',
      signIn: 'Giriş Yap'
    },
    message: {
      mailOrPasswordIncorrect: 'E-posta veya parola yanlış.',
      noMobile: 'Mobil giriş desteklenmiyor.',
      mailSendSuccess: 'Parola sıfırlama postası başarıyla gönderildi.',
      inValidMail: 'Lütfen formata uygun mail adresi girin.',
      minPasswordChar: 'Parolanız en az {{min}} karakter olmalı.',
      signUpSuccess: 'Kayıt başalarılı!',
      mailTaken: 'E-posta adresi kullanılıyor.',
      inValidNovusCode: 'Lütfen Novus ekibinden Dorğulama Kodu isteyiniz.',
      readyHaveAccount: 'Zaten hesabınız var mı? Giriş yap',
      dontHaveNovusCode: 'Doğrulama Kodunuz yok mu? Hemen al!',
      resetPasswordSuccess: 'Parolanız başarı ile değiştirildi.',
      resetPasswordError: 'Parolanız değiştirilemedi.',
      differentPasswords: 'Parolalar aynı değil.',
      emptyPassword: 'Parolanız boş olamaz.',
      passwordLength: 'Parola en az {{min}} en fazla {{max}} karakter olabilir'
    }
  },
  stats: {
    totalWordCount: '📄 Toplam Kelime Sayısı'
  },
  home: {
    home: 'Anasayfa',
    quickActions: 'Hızlı İşlemler',
    hotTopics: 'Gündemdeki Konular',
    myFolders: 'Klasörlerim',
    draft: 'Taslak',
    lastUpdatedAt: 'Son Güncelleme',
    profile: 'Profil',
    helpCenter: 'Yardım Merkezi',
    signOut: 'Çıkış Yap',
    recentlyUsedTemplates: 'Son Kullanılan Şablonlar',
    update: 'Güncelle',
    search: 'Ara',

    generateImage: 'Fotoğraf Üret',
    newProject: 'Yeni Proje',
    generateText: 'Yazı Üret',
    upgradePlan: 'Plan Yükselt',

    popularTopics: 'Popüler Konular',
    technology: 'Teknoloji',
    science: 'Bilim',
    cryptocurrency: 'Kripto Para',
    climateChange: 'İklim Değişikliği',
    economy: 'Ekonomi',

    showAll: 'Tümünü Göster',
    showFolders: 'Klasörleri Göster',

    newFolder: 'Yeni Klasör',
    selectFolder: 'Klasör Seç',
    moveArticle: 'Makaleyi Taşı',

    download: 'PDF İndir',
    folder: 'Klasör',
    createNewProject: 'Yeni Proje Oluştur',
    customTemplate: 'Özel Şablon',
    folderName: 'Klasör İsmi',
    projectName: 'Proje İsmi',
    create: 'Oluştur',
    save: 'Kaydet',
    editFolder: 'Klasörü Düzenle',
    editProject: 'Projeyi Düzenle',
    moveProjectInfo: 'Bu belgeyi zaten taslak olarak kaydettik. Bu projeyi bir klasöre taşımak istiyor musunuz?',
    moveProject: 'Projeyi Taşı',

    edit: 'Düzenle',
    deleteProject: 'Projeyi Sil',
    deleteFolder: 'Klasörü Sil',
    delete: 'Sil',

    notEmptyProjectName: 'Proje ismi boş olamaz.',
    notEmptyFolderName: 'Klasör ismi boş olamaz.',
    deleteProjectTitle: 'Projeyi silmek istediğinizden emin misiniz ?',
    deleteFolderTitle: 'Klasörü silmek istediğinizden emin misiniz ?',
    deleteProjectInfo: 'Projeyi silerseniz, kaydettiğiniz tüm veriler geri alınmamak üzere silinecektir.',
    deleteFolderInfo:
      'Klasörü silerseniz, içindeki tüm projeler ve kaydettiğiniz tüm veriler geri alınmamak üzere silinecektir.',
    logout: 'Oturumu Kapat',

    notFoundSaveEditor: 'Kaydedilmiş editör yazısı bulunamadı.'
  },
  account: {
    homepage: 'Anasayfa',
    personalInformation: 'Kişisel Bilgiler',
    passwordInformation: 'Şifre',
    subscription: 'Abonelik',
    upgradePlan: 'Plan Yükselt',

    orderSummary: 'Sipariş Özeti',
    billing: 'Fatura',

    planCanceled: 'Planınız iptal edildi. Planınınız {{end_date}} tarihinde yenilenmeyecek.',

    back: 'Geri',
    forward: 'Tamamla',

    profile: 'Profil',

    name: 'İsim',
    surname: 'Soyisim',
    email: 'Eposta',

    resetPassword: 'Şifre Sıfırla',
    successfullyResetPassword: 'Şifreniz başarıyla sıfırlandı',

    oldPassword: 'Eski şifre',
    newPassword: 'Yeni şifre',
    confirmPassword: 'Yeni şifre (Tekrar)',

    invalidCode: 'Geçersiz Kod',

    save: 'Kaydet',

    logout: 'Oturumu Kapat',

    updateError: 'Güncelleme sırasında bir hata oluştu. Lütfen daha sonra tekrar deneyin.',
    updateSuccess: 'Başarıyla güncellendi',

    contactUs: 'Bize Ulaşın',
    choosePlan: 'Plan Seç',
    monthly: 'Aylık',
    yearly: 'Yıllık',
    monthlyBilled: '${{price}}/Ay',
    yearlyBilled: 'Tutar ${{price}}/Yıl',

    planControllerDescription: {
      templates: '50+ Yapay zeka şablonları',
      languages_supported: '20+ desteklenen diller',
      high_quality_text_generation: 'High quality text generation',
      image_generation: 'Yüksek kaliteli metin oluşturma',
      fact_checking: 'Doğruluk kontrolü',
      plagiarism_check: 'Benzer yazı kontrolü',
      higher_quality_translation: 'Daha kaliteli çeviri',
      higher_resolution_images: 'Daha yüksek çözünürlüklü görüntüler',
      onboarding_and_training_sessions: 'İlk katılım ve eğitim oturumları',
      dedicated_account_manager: 'Özel hesap yöneticisi',
      style_calibration: 'Stil kalibrasyonu',
      content_agency: 'İçerik ajansı'
    }
  },
  article: {
    add: 'Ekle',
    addWithLink: 'Link ile Ekle',
    noInputEntered: 'Editöre prompt girişi yapılmadı. Lütfen prompt girişi yapın.',
    templates: 'Tüm Şablonlar',
    onboardingTitle: 'Kendi Özel Şablonunuzu Oluşturun',
    onboardingDescription:
      'Kendi AI şablonlarınızı oluşturmak artık her zamankinden daha kolay! Tek yapmanız gereken metin dosyalarını yüklemek ve Novus Writer kendi şablonunuzu oluşturacaktır.',
    onboardingButton: 'Hadi yapalım 🔮',

    instructionsTitle: '🔮 Yeni Şablon Oluştur',
    instructionsItemTitles: {
      1: 'Başlarken',
      2: 'Bir Belge Yükleyin',
      3: 'Modelinizi Tanımlayın',
      4: 'Şablon Hazırlama',
      5: 'Model Uygunluk Bildirimi',
      6: 'Özel Şablonunuzu Seçin',
      7: 'Dalış',
      8: `Yineleyin ve İyileştirin`,
      9: 'Özellikleri Birleştirin',
      10: `Kreasyonlarınızı Paylaşın!`
    },
    instructionsItemDescriptions: {
      1: 'Kendi özel yapay zeka modelinizi oluşturmak için üretken yapay zeka web uygulamasında "Özel Şablon" özelliğini bulun.',
      2: 'Özel şablonunuzu oluşturmaya başlamak için bir dosyayı sürükleyip bırakın veya gönderilecek dosyayı bilgisayarınızdan seçin.',
      3: 'Amacını ve kullanımını hatırlamanıza yardımcı olması için özel şablonunuz için benzersiz bir ad ve kısa bir açıklama girin.',
      4: 'Özel şablonunuzun işlenmesi için yaklaşık 2 saat bekleyin. Bu arada, web uygulamasındaki diğer özellikleri keşfetmekten ve kullanmaktan çekinmeyin.',
      5: 'Özel şablonunuz kullanıma hazır olduğunda sizi bilgilendireceğiz. Bildirime dikkat edin!',
      6: 'Yeni oluşturduğunuz özel şablonunuza web uygulamasındaki sol kenar çubuğundan erişin.',
      7: 'Yapay zeka modelinizden anlayışlı paragraflar oluşturmak için sorular sorarak veya istem olarak bir cümle sağlayarak özel şablonunuzu kullanmaya başlayın.',
      8: `Deneyiminize bağlı olarak, oluşturulan içeriğin alaka düzeyini ve kalitesini artırmak için özel şablonlarınızı değiştirmekten ve yeniden yüklemekten çekinmeyin.`,
      9: 'Özel şablonunuzun potansiyelini en üst düzeye çıkarmak için üretici yapay zeka web uygulamasındaki diğer özellikleri entegre ederek denemeler yapın.',
      10: `Eşsiz özel şablonlarınızı, AI modellerinizi kullanmaktan fayda sağlayabilecek meslektaşlarınız ve arkadaşlarınızla paylaşmayı unutmayın!`
    },
    instructionButton: 'Devam et',

    resultTitle: 'Özel şablonunuz hazırlanıyor!',
    resultItemTitles: {
      1: 'Şablon Hazırlanışı',
      2: 'Model Uygunluk Bildirimi'
    },
    resultItemDescriptions: {
      1: 'Özel şablonunuzun işlenmesi için yaklaşık 10 dakika bekleyin. Bu arada, web uygulamasındaki diğer özellikleri keşfetmekten ve kullanmaktan çekinmeyin.',
      2: 'Özel şablonunuz kullanıma hazır olduğunda sizi bilgilendireceğiz. Bildirime dikkat edin!'
    },
    resultButton: 'Anladım!',

    customAiOutputExampleLabel: 'Çıktı Örneği',
    customAiOutputExampleDescription: 'Müşteri için hangi sorunlu noktayı çözdünüz?',
    customAiOutputExamplePlaceholder: 'örneğin, bluetooth kulaklık, süper ucuz, kullanımı kolay',
    customAiTemplateDescriptionLabel: 'Şablon Açıklaması',
    customAiTemplateDescriptionDescription: 'Müşteri için hangi sorunlu noktayı çözdünüz?',
    customAiTemplateDescriptionPlaceholder: 'örneğin, bluetooth kulaklık, süper ucuz, kullanımı kolay',
    deleteAllButton: 'Tüm Dosyaları Sil',
    deleteAllTitle: 'Tüm dosyaları silmek istediğinizden emin misiniz?',
    deleteAllDescription: 'Dosyaları silerseniz, kaydettiğiniz tüm veriler kalıcı olarak silinir.',
    customTemplateFilesUploaded: 'Dosyalar yüklendi',
    customTemplateCreated: 'Özel şablonlar şu anda oluşturuluyor!',
    deleteSomeButton: 'Seçilen Dosyaları Sil',
    deleteSomeTitle: 'Seçilen dosyaları silmek istediğinizden emin misiniz?',
    deleteSomeDescription: 'Dosyaları silerseniz, kaydettiğiniz tüm veriler kalıcı olarak silinir.',
    customTemplatePreparingTitle: 'Özel şablonunuz hazırlanıyor!',
    customTemplatePreparingDescription:
      'Özel şablonunuz kullanıma hazır olduğunda sizi bilgilendireceğiz. Bildirime dikkat edin!',
    customTemplateMaxFileSizeReached:
      'Yükleyebileceğiniz toplam dosya boyutuna ulaştınız. Devam etmek için lütfen bazılarını silin.',
    customAiAddedFilesPlaceholder: 'Dosya Seçin ve Ekleyin',
    customAiAddedFilesDescription:
      'Özel şablon oluşturmak için Dosyalarım tabından en az bir dosya seçip eklemeniz gerekir.',
    customTemplateFieldRequired: '*Bu alan gereklidir',
    customTemplateAddFileButton: 'Ekle',
    inProgress: 'Devam ediyor',

    myFiles: 'Dosyalarım',
    uploadNew: 'Dosya Yükle',

    templatesSettings: 'Şablon Ayarları',
    currentTemplate: 'Seçili Şablon',
    otherTemplates: 'Diğer Şablonlar',
    customTemplates: 'Özel Şablonlar',
    shrinkPanel: 'Paneli Küçült',
    openPanel: 'Paneli Aç',
    you: 'Sen',
    view: 'Göster',

    upload: 'Yükle',
    uploads: 'Yüklemeler',

    download: 'İndir',

    plagiarism: 'Benzerlik',
    generalSimilarity: 'Genel Benzerlik',
    someOfThose: 'Bazıları',
    noPlagiarism: 'Benzerlik Yok',

    start: 'Başla',
    goToHome: 'Anasayfaya Git',
    customTemplate: 'Özel Şablon',
    createTemplate: 'Şablon Oluştur',
    trainingTemplate: 'Şablon oluşturuluyor',
    autoMlImageStartInformation: `Novus Writer'da kendi görsel şablonunuzu oluşturabilirsiniz. Bunun için belirli formatlarda istediğiniz konulara özel görseller yüklemeniz yeterlidir. Unutmayın, en iyi sonuçları 200 kurulumda alacaksınız.`,
    autoMlTextStartInformation: `Kendi AI şablonlarınızı oluşturmak artık her zamankinden daha kolay! Tek yapmanız gereken metin dosyalarını yüklemek ve Novus Writer kendi şablonunuzu oluşturacaktır.`,
    autoMlEndInformation:
      'Novus Writer belgeleri 1-3 saat boyunca işleyecek. Sistemdeki kayıtlı postanız olan {{email}} adresine bir bildirim e-posta alacaksınız.',

    autoMlImageUploadInformation: 'Bir veya daha fazla jpg, png görüntü dosyalarını sürükleyin',
    autoMlTextUploadInformation: '.txt or .docx dosyaları sürükleyin bırakın',
    or: 'veya',
    addFile: 'Dosya Aç',
    fileLoading: 'Dosya Yükleniyor...',
    noFiles: 'Kayıtlı dosya yok',
    uploadFile: 'Dosya Yükle',

    cancel: 'İptal',
    select: 'Seç',
    deleteAll: 'Tümünü Sil',
    delete: 'Sil',
    gotIt: 'Tamamdır!',

    deleteAllInformation: 'Tüm dosyaları silmek istediğinizden emin misiniz ?',

    templateName: 'Şablon İsmi',
    templateDescription: 'Şablon Açıklaması',

    againWrite: 'Yeniden Yazdır',

    regenerateSentence: 'Cümleyi yeniden oluştur',
    regenerateSuccessInfo: 'Cümleniz yeniden oluşturuluyor..',
    regenerateErrorInfo: 'Cümleniz yeniden oluşturulamadı. Lütfen daha sonra tekrar deneyin.',

    templateParent: {
      '': 'Şablon Kutusu',
      Communications: 'İletişim',
      UserTemplate: 'Özel Şablonlar',
      Beta: 'Beta',
      'Writing Tools': '✏️ Yazma Araçları',
      'SEO Boosters': '🔍 SEO Arttırıcılar',
      'Email Providers': '✉️ E-Posta Sağlayıcıları',
      'E-commerce': '🛒 E-Ticaret',
      'Video Builder': '📹 Video Oluşturucu',
      'Daily Use': '☀️ Günlük Kullanım',
      'Industrial Applications': '💼 Endüstriyel Uygulamalar'
    },

    templateNames: {
      Suggestions: 'Novus Sohbet',
      Healthcare: 'Sağlık Uzun',
      'Healthcare 2': 'Sağlık Kısa',
      Intro: 'Giriş',
      '2nd Grader Summarization': '2. Sınıf Özeti',
      'Third-person converter': 'Üçüncü Şahıs Dönüştürücü',
      'Ad Generation': 'Reklam Oluşturma',
      'Blog Outline / Ideas': 'Blog Anahatları / Fikirler',
      'Grammar correction': 'Dilbilgisi Düzeltmesi',
      'Create study notes': 'Çalışma Notları Oluşturun',
      'Meeting Notes': 'Toplantı Notları',
      'Paragraph Completion': 'Paragraf Tamamlama',
      Email: 'E-Posta',
      Summarize: 'Özetle',
      Rewrite: 'Yeniden Yazma',
      'Byte-Sized': 'Bayt Boyutunda',
      'Media Content Gen': 'Medya İçeriği',
      'Venti Article': 'Büyük Makale',
      'Mind Flow': 'Düşünme',
      'Title Creator': 'Başlık Oluşturucu',
      Paraphrase: 'Açıklama',
      'Detailed Suggestion': 'Öneri',
      SEO: 'SEO',
      'Complete Blog Post': 'Tam Blog Yazısı',
      'SEO Meta Title': 'SEO Meta Başlığı',
      'Rephrase with Synonyms': 'Eşanlamlılarla Yeniden Yaz',
      'The Athletic - Lebron edition': 'Atletik - Lebron Baskısı',
      'NYT Style': 'NYT Stili',
      'Novus Writing Assistant': 'Novus Yazma Asistanı',
      'Case Study': 'Örnek Olay İncelemesi',
      'Elevator Pitch': 'Asansör Konuşması',
      'Real Estate Listing': 'Gayrimenkul İlanı',
      Commands: 'Komutlar',
      'Video Description': 'Video Açıklaması',
      'Video Titles': 'Video Başlıkları',
      'Video Script': 'Video Metni',
      'Blog Outline & Ideas': 'Blog Anahatları ve Fikirler',
      'Seo Article': 'SEO Makalesi',
      'Seo UGC': 'SEO UGC',
      'Seo Leadership': 'SEO Liderlik',
      'Cold Email': 'Soğuk E-Posta',
      'Sales Sequence': 'Satış Sırası',
      'Google Ads Description': 'Google Ads Açıklaması',
      'Google Ads Headline': 'Google Ads Başlığı',
      'Etsy Product Description': 'Etsy Ürün Açıklaması',
      'Instagram Market Post': 'Instagram Pazarlama Gönderisi',
      'Easy Response': 'Kolay Cevap',
      'Pain Point Generator': 'Acı Nokta Oluşturucu',
      '1620': '1620',
      'Blog Post': 'Blog Yazısı',

      // Image template names
      Dalle2: 'DALL·E 2',
      'Free-Form': 'Serbest Biçim',
      'Human included realistic photos': 'İnsan Dahil Gerçekçi Fotoğraflar',
      'Portrait of people': 'İnsan Portresi',
      'Architecture & Landscape': 'Mimarlık ve Peyzaj',
      'Comic Book Style': 'Dinamik Görsel',
      Scenery: 'Manzara',
      'Material Photography': 'Malzeme Fotoğrafçılığı',
      Fashion: 'Moda',
      Cyberpunk: 'Siber Çılgınlık',
      'Asian Graphic Design': 'Asya Grafik Tasarımı',
      'Poster design': 'Poster Tasarımı',
      'Futuristic&Cyberpunk Style': 'Geleceğe Dönük ve Siber Çılgınlık Tarzı',
      'Animation&Manga': 'Animasyon ve Manga',
      Drawing: 'Çizim',
      Cinematic: 'Sinema',
      'Oil Painting': 'Yağlı Boya',
      'Social Media Post for Instagram': 'Sosyal Medya Gönderisi Instagram',
      'Social Media Post for Twitter': 'Sosyal Medya Gönderisi Twitter',
      'Social Media Post for Facebook': 'Sosyal Medya Gönderisi Facebook'
    },

    form: {
      1620: {
        topic: {
          title: 'Başlık',
          description: '',
          placeholder: "1620'nin değerleri ve şirket tarihi, sektör içgörülerini göz önünde bulundurarak"
        },

        tone: {
          title: 'Ton',
          description: '',
          placeholder: 'Profesyonel'
        },

        direction: {
          title: 'Yönelim',
          description: '',
          placeholder: 'Konu odaklı'
        }
      },

      Healthcare: {
        keyword_1: {
          title: 'Anahtar Kelime 1',
          description: '',
          placeholder: 'Örneğin, Robotik Cerrahi, Dermatoloji, vb.'
        },

        keyword_2: {
          title: 'Anahtar Kelime 2',
          description: '',
          placeholder: 'Örneğin, Robotik Fıtık Cerrahisi, Dermatoloji Kliniği, vb.'
        },

        keyword_3: {
          title: 'Keywords 3',
          description: '',
          placeholder_tr: 'Örneğin, Cilt, Saç, Kemik Hastalığı vb.'
        }
      },

      'Healthcare 2': {
        keyword_1: {
          title: 'Anahtar Kelime 1',
          description: '',
          placeholder: 'Örneğin, Robotik Cerrahi, Dermatoloji, vb.'
        },

        keyword_2: {
          title: 'Anahtar Kelime 2',
          description: '',
          placeholder: 'Örneğin, Robotik Fıtık Cerrahisi, Dermatoloji Kliniği, vb.'
        },

        keyword_3: {
          title: 'Keywords 3',
          description: '',
          placeholder_tr: 'Örneğin, Cilt, Saç, Kemik Hastalığı vb.'
        }
      },

      'Blog Post': {
        main_keyword: {
          title: 'Ana Kelime',
          description: '',
          placeholder: 'Örneğin, Taylor Swift, Singer vb. '
        },

        sub_keyword: {
          title: 'Anahtar Kelime',
          description: '',
          placeholder: 'Örneğin, Era tour vb. '
        },

        sub_keyword_2: {
          title: 'Anahtar Kelime 2',
          description: '',
          placeholder: 'Örneğin, Economic Boost vb. '
        }
      },

      'Case Study': {
        company: {
          title: 'Şirket',
          description: 'Bu vaka çalışması için hangi şirkete yazıyorsunuz?',
          placeholder: 'Örneğin, Koala Inc.'
        },
        customer: {
          title: 'Müşteri',
          description: 'Hangi müşteriyi dikkat alıyorsunuz?',
          placeholder: 'Örneğin, Panda Inc.'
        },
        challenge: {
          title: 'Zorluk',
          description: 'Müşterinizin karşılaştığı zorluk nedir?',
          placeholder: `Örneğin, Panda'nın SaaS ürünü için yeni bir UI/UX'a ihtiyacı var ve bu sayede müşterilerinin ilk katılım verimliliğini artırmayı hedefliyor.`
        },
        solution: {
          title: 'Çözüm',
          description: 'Örnek çözüm',
          placeholder:
            'Koala, geliştirme ekibiyle birlikte danışmanlık ve tasarım süreci sunmuş ve yerleşik odaklı tasarımı sağlamıştır.'
        },
        results: {
          title: 'Sonuç',
          description: 'Hedef sonuç',
          placeholder: 'Panda, işe alım süresini 2 saatten 15 dakikaya indirdi.'
        }
      },
      'Elevator Pitch': {
        product_name: {
          title: 'Ürün Adı',
          description: 'Ürün adı ve kategorisi',
          placeholder: 'Gmail'
        },
        problem: {
          title: 'Problem',
          description: 'Elinizdeki sorun nedir?',
          placeholder: 'Verilere sınırlı insan erişimi'
        },
        solution: {
          title: 'Çözüm',
          description: 'Önerilen çözüm nedir?',
          placeholder: 'E-postalar için kontrolü kolay dosyalama sistemi'
        },
        key_benefit: {
          title: 'Ana Fayda',
          description: 'Çözümünüzün temel faydası',
          placeholder:
            'Kullanıcıların tek bir tıklama ile e-postalarınızı kontrol edebilmesini ve oluşturabilmesini sağlayın'
        }
      },
      'Real Estate Listing': {
        information: {
          title: 'Bilgi',
          description: 'Listelenecek ev hakkında bilgi',
          placeholder: "4 yatak, 3 banyo, 1,4 dönüm arsa. büyük bahçe, meyve ağaçları. TD Arena'nın yanında"
        },
        pushing_details: {
          title: 'Zorlayıcı Detaylar',
          description: 'Vurgulanması gereken önemli noktalar',
          placeholder: 'Riverside, maçları evden izlemeye izin veriyor'
        },
        tone: {
          title: 'Ton',
          description: 'Ton',
          placeholder: 'Profesyonel'
        }
      },
      Commands: {
        command: {
          title: 'Komut',
          description: 'Verilecek emir',
          placeholder:
            "Usta bir satranç oyuncusu gibi davranın ve Queen's Gambit serisinin son oyunu hakkında yorum yapın"
        },
        additional_details: {
          title: 'Ek Detaylar',
          description: 'Eklenecek ilave ayrıntılar',
          placeholder: "Beth'in en sevdiği iki açılış Vezir Gambiti ve Sicilya Savunmasıdır"
        },
        tone: {
          title: 'Ton',
          description: 'Ton',
          placeholder: 'Bilgilendirici'
        }
      },
      'Video Description': {
        video_topic: {
          title: 'Video Konusu',
          description: 'Konu',
          placeholder: 'Slime yarışması ve Iphone çekilişi'
        },
        keywords: {
          title: 'Anahtar Kelimeler',
          description: 'Sıralama için dikkate alınması gereken anahtar kelimeler',
          placeholder: "Beth'in en sevdiği iki açılış Vezir Gambiti ve Sicilya Savunmasıdır"
        },
        tone: {
          title: 'Ton',
          description: 'Ton',
          placeholder: 'Profesyonel'
        }
      },
      'Video Titles': {
        video_topic: {
          title: 'Video Topic',
          description: 'Topic',
          placeholder: 'Best places to eat in Boston'
        },
        keywords: {
          title: 'Keywords',
          description: 'Keywords to consider for ranking',
          placeholder: 'Boston, Oyster'
        },
        tone: {
          title: 'Tone',
          description: 'Ton',
          placeholder: 'Informative'
        }
      },
      'Video Script': {
        video_topic: {
          title: 'Video Başlığı',
          description: 'Başlık',
          placeholder: 'Dünya üzerindeki en büyük binalar'
        },
        tone: {
          title: 'Ton',
          description: 'Ton',
          placeholder: 'Bilgilendirici'
        }
      },
      'Ad Generation': {
        company_definition: {
          title: 'Şirket Tanımı',
          description: 'Reklam için kullanılacak şirket tanımı',
          placeholder: 'Novus writer işletmeleri özel yapay zeka ile güçlendiriyor'
        }
      },
      'Blog Outline & Ideas': {
        blog_topic: {
          title: 'Blog Konusu',
          description: 'Hakkında yazmak istediğiniz konu',
          placeholder: 'Boston deniz ürünleri'
        },
        tone: {
          title: 'Ton',
          description: '',
          placeholder: 'Konuşmaya Dayalı, Bilgilendirici'
        }
      },
      'Seo Article': {
        topic: {
          title: 'Konu',
          description: 'Hakkında yazmak istediğiniz konu',
          placeholder: 'Sağlık turizmi'
        },
        keywords: {
          title: 'Anahtar Kelimeler',
          description: '',
          placeholder: 'Saç Ekimi, İstanbul, En İyi'
        }
      },
      'SEO Article': {
        topic: {
          title: 'Konu',
          description: 'Hakkında yazmak istediğiniz konu',
          placeholder: 'Sağlık turizmi'
        },
        keywords: {
          title: 'Anahtar Kelimeler',
          description: '',
          placeholder: 'Saç Ekimi, İstanbul, En İyi'
        }
      },
      'Instagram Market Product': {
        product_name: {
          title: 'Ürün Adı',
          description: '',
          placeholder: 'Jelly Snake'
        },
        written_by: {
          title: 'Written by',
          description: '',
          placeholder: 'Nietzsche'
        },
        product_description: {
          title: 'Ürün Açıklaması',
          description: '',
          placeholder: 'Evcil hayvanların oynaması ve çiğnemesi için jelatin yılanlar'
        }
      },
      'Seo UGC': {
        headline: {
          title: 'Manşet',
          description: 'Ana nokta',
          placeholder: 'Youtube bir sonraki pazar'
        },
        keywords: {
          title: 'Anahtar Kelimeler',
          description: '',
          placeholder: 'UGC, Youtube, Video'
        },
        tone: {
          title: 'Ton',
          description: '',
          placeholder: 'Komik'
        }
      },
      'Seo Leadership': {
        headline: {
          title: 'Manşet',
          description: 'Ana nokta',
          placeholder: 'Riskte ustalaşmak'
        },
        keywords: {
          title: 'Anahtar Kelimeler',
          description: '',
          placeholder: 'Liderlik, risk yönetimi'
        },
        tone: { title: 'Ton', description: '', placeholder: 'Mizahi' }
      },
      'Cold Email': {
        product: {
          title: 'Sizin Ürününüz',
          description: 'Ürününüz nedir',
          placeholder: 'Kafe sahiplerinin kafe oturumlarını yönetmelerine yardımcı olan internet kafe yazılımı'
        },
        product_name: {
          title: 'Ürün Adı',
          description: '',
          placeholder: 'Akin soft'
        },
        context: {
          title: 'Bağlam',
          description: 'E-postaya dahil edilecek bağlam',
          placeholder: 'Vegas kongresinde tanışmıştık.'
        },
        tone: {
          title: 'Ton',
          description: '',
          placeholder: 'Konuşmaya Dayalı, Bilgilendirici'
        }
      },
      'Sales Sequence': {
        company: {
          title: 'Şirket',
          description: '',
          placeholder:
            'Venture Lane, iyi bir konumda uygun maliyetli bir ofis arayan işletmeler için bir ortak çalışma alanıdır'
        },
        tone: {
          title: 'Ton',
          description: 'Ton',
          placeholder: 'Profesyonel'
        }
      },
      'Google Ads Description': {
        product_name: {
          title: 'Ürün Adı',
          description: '',
          placeholder: 'Jelly Snake'
        },
        product_description: {
          title: 'Ürün Açıklaması',
          description: '',
          placeholder: 'Evcil hayvanların oynaması ve çiğnemesi için jelatin yılanlar.'
        },
        tone: {
          title: 'Ton',
          description: '',
          placeholder: 'Mizahi'
        },
        example: {
          title: 'Örnek',
          description: '',
          placeholder: 'Plastik yok, bir kenara atılacak başka bir evcil hayvan oyuncağı değil.'
        }
      },
      'Google Ads Headline': {
        product_name: {
          title: 'Ürün Adı',
          description: '',
          placeholder: 'Jelly Snake'
        },
        product_description: {
          title: 'Ürün Açıklaması',
          description: '',
          placeholder: 'Evcil hayvanların oynaması ve çiğnemesi için jelatin yılanlar.'
        },
        tone: {
          title: 'Ton',
          description: '',
          placeholder: 'Mizahi'
        },
        example: {
          title: 'Örnek',
          description: '',
          placeholder: 'Plastik yok, bir kenara atılacak başka bir evcil hayvan oyuncağı değil.'
        }
      },
      'Etsy Product Description': {
        product_name: {
          title: 'Ürün Adı',
          description: '',
          placeholder: 'El yapımı bavul'
        },
        key_benefits: {
          title: 'Ana Faydalar',
          description: '',
          placeholder: 'Sürdürülebilir malzemeler, Antimikrobiyal, otantik'
        },
        tone: {
          title: 'Ton',
          description: '',
          placeholder: 'neşeli'
        }
      },
      'Instagram Market Post': {
        product_name: {
          title: 'Ürün Adı',
          description: '',
          placeholder: 'Jelly Snake'
        },
        written_by: {
          title: 'Written by',
          description: '',
          placeholder: 'Nietzsche'
        },
        product_description: {
          title: 'Ürün Açıklaması',
          description: '',
          placeholder: 'Evcil hayvanların oynaması ve çiğnemesi için jelatin yılanlar'
        }
      },
      'Easy Response': {
        received_email: {
          title: 'Alınan Posta',
          description: '',
          placeholder:
            'Makine öğrenimi departmanı olan 2 numaralı yazılım geliştirme ekibinin dün bildirdiğimiz görevi yarına kadar tamamlayabilmesi mümkün mü?'
        },
        response_message: {
          title: 'Yanıt Mesajı',
          description: '',
          placeholder: 'Hayır, bunu yapamayız. Bizi daha önce bilgilendirmelisiniz.'
        },
        tone: {
          title: 'Ton',
          description: '',
          placeholder: 'konuşkan, %25 spartan'
        }
      },
      'Pain Point Generator': {
        company: {
          title: 'Şirket',
          description: '',
          placeholder: 'Pigastro'
        },
        target: {
          title: 'Hedef',
          description: '',
          placeholder: 'Öğrenciler ve üniversiteler'
        },
        value_props: {
          title: 'Değer Önerileri',
          description: '',
          placeholder: 'Ucuz ve kaliteli yiyecekler'
        },
        objectives: {
          title: 'Hedefler',
          description: '',
          placeholder: 'Daha fazla kampüse ulaşmak'
        }
      },
      '1620 Trained': {
        topic: {
          title: 'Konu',
          description: '',
          placeholder: "1620'nin değerleri ve şirket geçmişi, sektör içgörüleri göz önünde bulundurularak"
        },
        tone: {
          title: 'Ton',
          description: '',
          placeholder: 'Profesyonel'
        },
        direction: {
          title: 'Yön',
          description: '',
          placeholder: 'Konu odaklı'
        }
      }
    },

    factAndOutputSame: 'Doğruluk kontrolü dili, çıktı dili ile aynı olsun',
    suggestionTextLength: 'Yazı Uzunluğu',

    numberOfImage: 'Resim sayısı',
    aestheticRating: 'Estetik Derecesi',
    aestheticWeight: 'Estetik Ağırlık',
    guidanceScale: 'Rehberlik Ölçeği',
    promptStrength: 'İstem Gücü',
    relevance: 'Alaka',
    seed: 'Tohum',
    trialSteps: 'Deneme Adımları',

    homepage: 'Anasayfa',
    projectName: 'Proje İsmi',
    template: 'Şablon',

    saveFeedback: 'Tüm değişiklikleriniz kaydediliyor',
    unSaveFeedback: 'Makale kaydedilmedi',

    askNovus: `Novus Writer'a soru sorun`,

    goToEditor: 'Editore git',
    goToInput: 'Inputa git',

    uploadImage: 'Resim Yükle',
    drawImage: 'Resim Çiz',

    text: 'Yazı',
    image: 'Resim',

    textGeneratorPlaceholder: 'Oluşturmak istediğin konuyla ilgili biraz içerik gir ...',
    imageGeneratorPlaceholder: 'Hayalindeki resmi gir ...',
    editorPlaceholder: 'Ürettiğin metinleri kopyalayıp buraya yapıştırarak sen de içeriğini oluşturmaya başla...',

    title: 'Başlık',
    optional: 'İsteğe Bağlı',

    create: 'Oluştur',
    save: 'Kaydet',

    noGenerationTop: 'Yandaki kısma metin girerek',
    noGenerationBottom: 'yeni içerikleri burada görüntüleyin',

    copySuccessful: 'Başarıyla kopyalandı!',
    downloadSuccesful: 'Başarıyla indirildi!',
    deleteBookmarkSuccessful: 'Başarıyla kaydedilenlerden silindi!',
    bookmarkSuccessful: 'Başarıyla kaydedildi!',

    copy: 'Kopyala',
    removeFromBookmarks: 'Kaydedilenlerden sil',

    bookmarks: 'Kaydedilenler',

    showContentSource: 'Medya kaynağını görüntüle >>',

    tab: 'Sekme',

    languageSettings: 'Dil Ayarları',
    inputLanguage: 'Giriş Dili',
    outputLanguage: 'Çıkış Dili',

    proofRead: 'Benzersizlik kontrolü',

    factCheckIsLoading: 'Cümlelerin doğruluk kontrolü yapılıyor...',

    notFoundSaveEditor: 'Kaydedilmiş editör yazısı bulunamadı.'
  },
  common: {
    usageLimitReached: 'Kullanım sınırınıza ulaştınız veya planınınızın süresi doldu!',

    getTemplatesError: 'Yazı şablonları ile ilgili bir sorun yaşandı. Lütfen daha sonra tekrar deneyin!',
    getTemplatesDalleError: 'Resim şablonları ile ilgili bir sorun yaşandı. Lütfen daha sonra tekrar deneyin!',
    saveArticleError: 'Makale kaydedilirken bir sorun yaşandı. Lütfen daha sonra tekrar deneyin!',
    getSavedAiTextGeneratinsError: 'Kaydedilmiş yazı önerileri getirilemedi. Lütfen daha sonra tekrar deneyin!',
    getSavedAiImageGeneratinsError: 'Kaydedilmiş resim önerileri getirilemedi. Lütfen daha sonra tekrar deneyin!',
    newAiTextGenerationError: 'Yeni yazı önerileri üretilemedi. Lütfen daha sonra tekrar deneyin!',
    newAiImageGenerationError: 'Yeni resim önerileri üretilemedi. Lütfen daha sonra tekrar deneyin!',
    getEditorStateError: 'Editör kaydı çekilemedi. Lütfen daha sonra tekrar deneyin!',
    saveEditorStateError: 'Editör kaydedilemedi. Lütfen daha sonra tekrar deneyin!',

    copyError: 'Kopyalanamadı. Lütfen daha sonra tekrar deneyin!',
    downloadError: 'İndirilemedi. Lütfen daha sonra tekrar deneyin!',
    bookmarkError: 'Kaydedilemedi. Lütfen daha sonra tekrar deneyin!',
    removeBookmarkError: 'Kaydedilenler silinemedi. Lütfen daha sonra tekrar deneyin!',
    factCheckError: 'Doğrulama kontrol yapılamadı. Lütfen daha sonra tekrar deneyin!',
    factCheckLowPlanError: 'Doğruluk kontrolünü kullanmak için planınızı yükseltmelisiniz.',
    factCheckLimitError: 'Fact check kullanım limiti doldu.',

    somethingsWrongError: 'Bir şeyler yanlış gitti. Lütfen daha sonra tekrar deneyin!',
    contentNotEmpty: 'İçerik boş olamaz!'
  },
  'Free-form': {}
};

export default translations;
