import { APPROACH_OPTIONS, AUDIENCE_OPTIONS, MIND_FLOW_TEMPLATE_NAME, PLATFORM_OPTIONS } from 'config/constants';
import AppSelectbox from 'features/common/AppSelectbox';
import { memo, useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { mergeTextGenerationSetting, setTextGenerationSettings } from 'redux/editor';
import { getEntities, getEntity } from 'redux/entity';
import styled from 'styled-components';
import { useSelect } from 'utils/hooks/useSelect';
import { ApproachOption, AudienceOption, PlatformOption, ToneOption } from 'utils/types';
import { ArticleContext } from 'utils/contexts/ArticleContext';

export const ArticleDrawerSelectBox = memo(() => {
  const dispatch = useDispatch();
  const { articleId } = useContext(ArticleContext);

  const textSettings = useSelect(({ editor }) => editor.textGenerationSettings);
  const aiTemplates = useSelect(({ entity }) => getEntities(entity, 'aiTemplate'));
  const textTemplate = useSelect(({ editor, entity }) => {
    const templateId = editor.selectedTextTemplateId[articleId];
    return getEntity(entity, 'template', templateId);
  });

  const shouldShowAiTemplateOptions = textTemplate?.render?.includes('template');
  const shouldShowToneOptions = textTemplate?.render?.includes('tone');
  const shouldShowPlatformOptions = textTemplate?.render?.includes('platform');
  const shouldShowAudienceOptions = textTemplate?.render?.includes('audience');
  const shouldShowApproachOptions = textTemplate?.name === MIND_FLOW_TEMPLATE_NAME;

  const visible =
    shouldShowAiTemplateOptions ||
    shouldShowToneOptions ||
    shouldShowPlatformOptions ||
    shouldShowAudienceOptions ||
    shouldShowApproachOptions;

  // @handlers

  const onAiTemplateChange = useCallback(
    (newAiTemplate: string) => {
      const aiTemplate = aiTemplates?.find(({ id }) => id === newAiTemplate);
      if (aiTemplate) dispatch(mergeTextGenerationSetting(aiTemplate));
    },
    [aiTemplates]
  );

  // @render

  return (
    <Container visible={visible}>
      <GenericSelectBox
        shouldShow={shouldShowAiTemplateOptions}
        label="Template"
        defaultValue={textSettings.aiTemplates?.id}
        options={aiTemplates?.map((ait) => ({ label: ait.name, value: ait.id })) ?? []}
        setter={onAiTemplateChange}
      />

      <GenericInput
        shouldShow={shouldShowToneOptions}
        label="Tone"
        defaultValue={textSettings.tone}
        placeholder="e.g Friendly, Formal, Informal"
        setter={(tone) => dispatch(setTextGenerationSettings({ tone: tone as ToneOption }))}
      />

      <GenericSelectBox
        shouldShow={shouldShowPlatformOptions}
        label="Platform"
        defaultValue={textSettings.platform}
        options={PLATFORM_OPTIONS}
        setter={(platform: PlatformOption) => dispatch(setTextGenerationSettings({ platform }))}
      />

      <GenericSelectBox
        shouldShow={shouldShowAudienceOptions}
        label="Audience"
        defaultValue={textSettings.audience}
        options={AUDIENCE_OPTIONS}
        setter={(audience: AudienceOption) => dispatch(setTextGenerationSettings({ audience }))}
      />

      <GenericSelectBox
        shouldShow={shouldShowApproachOptions}
        label="Approach"
        defaultValue={textSettings.approach}
        options={APPROACH_OPTIONS}
        setter={(approach: ApproachOption) => dispatch(setTextGenerationSettings({ approach }))}
      />
    </Container>
  );
});

const GenericSelectBox = memo<{
  shouldShow?: boolean;
  label: string;
  defaultValue?: string;
  options: { label: string; value: string }[];
  setter: (payload: any) => void;
}>(({ shouldShow, label, defaultValue, options, setter }) => {
  if (!shouldShow) return null;

  return (
    <SelectBox
      label={label}
      defaultValue={options.find((o) => o.value === defaultValue)}
      options={options ?? []}
      onChange={(newValues) => setter(newValues?.[0].value)}
    />
  );
});

const GenericInput = memo<{
  shouldShow?: boolean;
  label: string;
  defaultValue?: string;
  placeholder?: string;
  setter: (payload: string) => void;
}>(({ shouldShow, label, defaultValue, placeholder, setter }) => {
  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setter(e.target.value);
  };

  if (!shouldShow) return null;

  return (
    <>
      <InputLabel>{label}</InputLabel>
      <Input defaultValue={defaultValue} placeholder={placeholder} onChange={handleValueChange} />
    </>
  );
});

const Container = styled.div<{ visible: boolean }>`
  width: 182px;
  margin-top: 16px;
  margin-bottom: 20px;
  border-top: ${({ visible }) => (visible ? 1 : 0)}px solid ${({ theme }) => theme.colors.border[40]};

  & > div:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const Input = styled.input`
  padding: 10px;
  border-radius: 4px;
  gap: 6px;
  width: 100%;
  border: rgba(255, 255, 255, 0.08) 1px solid;
  background-color: rgba(255, 255, 255, 0.05);
  color: ${({ theme }) => theme.colors.white};

  margin-bottom: 12px;
  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray[10]};
    opacity: 0.5;
  }
`;

const InputLabel = styled.label`
  margin-top: 20px;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.white};
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
`;

const SelectBox = styled(AppSelectbox)`
  & > p {
    color: ${({ theme }) => theme.colors.white};
  }

  & [alt='arrow-down'] {
    color: ${({ theme }) => theme.colors.white} !important;
  }

  & > div {
    border: 1px solid ${({ theme }) => theme.colors.gray[60]};
    border-radius: 4px;
    border: rgba(255, 255, 255, 0.1) 1px solid;
    background-color: rgba(255, 255, 255, 0.05);

    & > div {
      background: transparent !important;

      & > :not(:nth-child(1), :nth-child(2)) {
        background: linear-gradient(130deg, #561a55 0%, #641da9 90%) !important;
        border-radius: 4px;
        border: rgba(255, 255, 255, 0.08) 1px solid;
      }

      & > div > span {
        color: ${({ theme }) => theme.colors.white} !important;
        border: none;
        font-family: Nunito Sans;
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        padding: 10px;
        width: 100%;
      }

      & > div > p {
        color: rgba(250, 251, 253, 0.5) !important;
      }
    }
  }
`;
