import { Label } from 'features/common/Styles';
import { orderBy } from 'lodash';
import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getEntities } from 'redux/entity';
import styled from 'styled-components';
import { useSelect } from 'utils/hooks/useSelect';
import { Template } from 'utils/types';
import { ArticleContext } from '../../utils/contexts/ArticleContext';
import { ArticleDrawerTemplateListItem } from './article/ArticleDrawerTemplateListItem';

export const DrawerRecentTemplateList: React.FC<{
  showLongTitle?: boolean;
  onTemplateClick: (template: Template) => void;
}> = ({ showLongTitle, onTemplateClick }) => {
  const { t } = useTranslation(['home']);
  const { articleId } = useContext(ArticleContext);

  const templateMeta = useSelect(({ entity }) => getEntities(entity, 'templateMeta'));
  const rawTemplates = useSelect(({ entity }) => getEntities(entity, 'template'));

  const selectedTemplateId = useSelect(({ editor }) => editor.selectedTextTemplateId[articleId] ?? '');

  const templates = useMemo(() => {
    if (!templateMeta.length) return [];
    const templatesWithUsage = rawTemplates.map((template) => {
      const templateData = templateMeta.find((data) => data.template_id === template.id);
      const last_used_at = templateData ? -templateData.last_used_at : Number.MAX_SAFE_INTEGER;
      return { ...template, last_used_at };
    });

    const orderedTemplates = orderBy(templatesWithUsage, ['last_used_at', 'order'], ['asc']);
    return orderedTemplates.slice(0, 4);
  }, [templateMeta, rawTemplates]);

  // @handlers

  const handleSelectTemplate = useCallback(
    (template: Template) => () => {
      onTemplateClick(template);
    },
    [onTemplateClick]
  );

  // @render

  return (
    <Container style={{ display: templates.length ? 'flex' : 'none' }}>
      <Title>{t('recentlyUsedTemplates')}</Title>
      {templates.map((template, index) => (
        <ArticleDrawerTemplateListItem
          key={template.id}
          template={template}
          isLastItem={index === templates.length - 1}
          removePadding
          isSelected={template.id === selectedTemplateId}
          onTemplateClick={handleSelectTemplate(template)}
        />
      ))}
    </Container>
  );
};

const Title = styled(Label)`
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  line-height: 19px;
  min-width: 190px;
  color: ${({ theme }) => theme.colors.gray[15]};
  margin-bottom: 10px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin-top: 20px;
  padding: 0;
  & > :not(:last-child) {
    margin-bottom: 4px;
  }
`;
