import { memo, useContext, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGetUserPlanQuery } from 'redux/user/api';
import { useSelect } from 'utils/hooks/useSelect';
import { HomeContext } from 'utils/contexts/HomeContext';
import { Template } from 'utils/types';
import { DrawerRecentTemplateList } from './DrawerRecentTemplateList';
import { Col, FlexCol, Label } from 'features/common/Styles';
import { ReactComponent as NovusMiniSvg } from 'assets/img/NovusMini.svg';
import { ReactComponent as RightArrow } from 'assets/img/icons/ArrowRight.svg';
import { ReactComponent as DownArrow } from 'assets/img/icons/ArrowDown.svg';
import { ReactComponent as LogOut } from 'assets/img/icons/Logout.svg';
import { ReactComponent as EyeIcon } from 'assets/img/icons/Home.svg';
import { ReactComponent as HelpIcon } from 'assets/img/icons/help.svg';
import { ReactComponent as UpgradeIcon } from 'assets/img/icons/upgrade.svg';
import { UserAvatar } from 'features/common/UserAvatar';
import styled from 'styled-components';
import { animated, easings, useSpring } from 'react-spring';

export const HomeDrawer = memo<{
  onCreateArticle: (template?: Template) => void;
}>(({ onCreateArticle }) => {
  const { push } = useHistory();
  const { setModalType } = useContext(HomeContext);

  const user = useSelect(({ me }) => me.user);
  const { t } = useTranslation(['article', 'common', 'home']);

  const [open, setOpen] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const openAnimation = useSpring({
    from: { opacity: 1, width: '230px' },
    to: { width: open ? '230px' : '0px' },
    config: { duration: 250, easing: open ? easings.easeOutQuad : easings.easeInQuad }
  });

  const arrowAnimation = useSpring({
    from: { opacity: 1 },
    to: { rotate: open ? '180deg' : '0deg' },
    config: { duration: 250, easing: open ? easings.easeOutQuad : easings.easeInQuad }
  });

  const arrowDownAnimation = useSpring({
    transform: isMenuOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    config: { duration: 250, easing: isMenuOpen ? easings.easeOutQuad : easings.easeInQuad }
  });

  const { data: userPlan } = useGetUserPlanQuery();

  // @handlers

  const handleCreateCustomTemplate = useCallback(() => {
    setModalType('customTemplate');
  }, []);

  const handleDrawerToggle = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const handleMenuToggle = useCallback(() => {
    setIsMenuOpen((prev) => !prev);
  }, []);

  const handleLogout = useCallback(() => {
    setModalType('logout');
  }, []);

  const navigate = useCallback(
    (path: string) => () => {
      push(path);
    },
    []
  );

  // @render

  return (
    <Container>
      <Content style={openAnimation}>
        <Col style={{ padding: 20, paddingBottom: 0 }}>
          <NovusMiniSvg color="white" height={30} width={30} />
          <Col style={{ marginTop: 20 }}>
            <CustomTemplateButton onClick={() => onCreateArticle()}>
              <Label style={{ fontWeight: 600, fontSize: 14, color: 'white', marginLeft: 4 }}>
                📄 {t('home:createNewProject')}
              </Label>
            </CustomTemplateButton>
            <CustomTemplateButton onClick={handleCreateCustomTemplate}>
              <Label style={{ fontWeight: 600, fontSize: 14, color: 'white' }}>🔮 {t('customTemplate')}</Label>
            </CustomTemplateButton>
          </Col>
        </Col>
        <ScrollContainer>
          <FlexCol style={{ padding: 20, paddingTop: 0 }}>
            {open && <DrawerRecentTemplateList onTemplateClick={onCreateArticle} />}
          </FlexCol>
          {open && (
            <DrawerBottom>
              <DrawerProperty onClick={handleMenuToggle}>
                <UserAvatar />
                <UserInfoContainer>
                  <UserName>{user?.first_name + "'s Workspace"}</UserName>{' '}
                  <UserPlan>{userPlan?.purchased_plan?.name || 'No Plan'}</UserPlan>
                </UserInfoContainer>
                <animated.div style={arrowDownAnimation}>
                  <DownArrow width={12} height={12} color="hsla(0, 0%, 100%, 1)" style={{ opacity: 0.5 }} />
                </animated.div>
              </DrawerProperty>
              {isMenuOpen && (
                <DrawerBottomMenu>
                  <DrawerPropertyItem onClick={navigate('/account/personal-information')}>
                    <UserAvatar size="small" /> {t('home:profile')}
                  </DrawerPropertyItem>
                  <DrawerPropertyItem onClick={navigate('/account/upgrade-plan')}>
                    <UpgradeIcon />
                    {t('home:upgradePlan')}
                  </DrawerPropertyItem>
                  {user?.is_admin ? (
                    <DrawerPropertyItem onClick={navigate('/admin')}>
                      <EyeIcon />
                      {t('home:adminPanel')}
                    </DrawerPropertyItem>
                  ) : null}
                  <DrawerPropertyItem>
                    <HelpIcon />
                    {t('home:helpCenter')}
                  </DrawerPropertyItem>
                  <DrawerPropertyItem onClick={handleLogout}>
                    <LogOut width={19} height={19} /> {t('home:logout')}
                  </DrawerPropertyItem>{' '}
                </DrawerBottomMenu>
              )}
            </DrawerBottom>
          )}
        </ScrollContainer>
      </Content>

      <DrawerOnOff onClick={handleDrawerToggle}>
        <DrawerBackgroundLayer>
          <animated.div style={arrowAnimation}>
            <RightArrow width={20} height={20} />
          </animated.div>
        </DrawerBackgroundLayer>
      </DrawerOnOff>
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  height: 100vh;
  justify-content: space-between;
  overflow: hidden;
`;

const Content = styled(animated.div)`
  display: flex;
  background: linear-gradient(130deg, #561a55 0%, #641da9 50%);
  flex-direction: column;
  justify-content: space-between;
`;

const ScrollContainer = styled(FlexCol)`
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const DrawerBackgroundLayer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.05);
  padding: 0px 5px;
  align-items: center;
  cursor: pointer;
`;

const DrawerOnOff = styled.div`
  background: linear-gradient(130deg, #561a55 0%, #641da9 50%);
  color: ${({ theme }) => theme.colors.white};
`;

const DrawerBottom = styled.div`
  padding: 20px;
`;

const DrawerProperty = styled.div`
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 14px;
  align-self: stretch;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.05);
  cursor: pointer;
  width: 190px;

  &:hover {
    background: rgba(255, 255, 255, 0.15);
  }
`;

const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const UserName = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const UserPlan = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const DrawerBottomMenu = styled.div`
  display: flex;
  width: 190px;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 13px;
  font-size: 14px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.05);
  color: hsla(0, 0%, 100%, 1);
  position: fixed;
  bottom: 75px;
`;

const DrawerPropertyItem = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  color: hsla(0, 0%, 100%, 1);
  cursor: pointer;

  & > :first-child {
    margin-right: 10px;
  }
`;

const CustomTemplateButton = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 190px;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  margin-bottom: 4px;
  color: ${({ theme }) => theme.colors.white};
  background-color: rgba(255, 255, 255, 0.05);

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &:active {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;
