import CancelIcon from 'assets/img/icons/cancel.svg';
import CheckIcon from 'assets/img/icons/ok.svg';
import { memo } from 'react';
import styled from 'styled-components';
import { PlanType } from 'utils/types';

export const PlanListItem = memo<{
  item: PlanType['pros'];
}>(({ item }) => {
  // @render

  return (
    <StyledDiv>
      {item.map((pros, index: number) => {
        return (
          <StyledPlan key={index}>
            <div>
              <img alt="" src={pros.value ? CheckIcon : CancelIcon} width={20} height={20} />
            </div>
          </StyledPlan>
        );
      })}
    </StyledDiv>
  );
});

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  /*   & > div:first-child {
    width: 27.5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    border-left: 1px solid #cdd1de;
    border-top: 1px solid #cdd1de;
  } */
`;

const StyledPlan = styled.div`
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 22px;
  border-left: 1px solid #cdd1de;
  border-top: 1px solid #cdd1de;
  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
