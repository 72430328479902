import { UPGRADE_OR_DOWNGRADE_USER_PLAN_CACHE_KEY } from 'config/constants';
import { memo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useUpgradeOrDowngradePlanMutation } from 'redux/auth/api';
import { PlanType } from 'utils/types';
import { Plans } from './Plans';

import { ReviewOrder } from './ReviewOrder';

export const UpgradePlan = memo(() => {
  const history = useHistory();

  const [upgradeOrDowngradePlanApi] = useUpgradeOrDowngradePlanMutation({
    fixedCacheKey: UPGRADE_OR_DOWNGRADE_USER_PLAN_CACHE_KEY
  });

  const [selectedPlan, setSelectedPlan] = useState<PlanType>();
  const [step, setStep] = useState(0);

  // @handlers

  const onSelectPlan = (plan: PlanType) => {
    setSelectedPlan(plan);
    setStep(1);
  };

  const onPressNext = async () => {
    try {
      if (!selectedPlan || !selectedPlan.id) throw new Error('No plan');

      const { checkoutFormSessionUrl } = await upgradeOrDowngradePlanApi(selectedPlan.id).unwrap();
      if (checkoutFormSessionUrl) window.location = checkoutFormSessionUrl;
      else {
        toast.success('Changing plan is successful!');
        history.replace('/account/subscription');
      }
    } catch (error) {
      console.error('error', error);
    }
  };

  // @render

  switch (step) {
    case 0:
      return <Plans onSelectPlan={onSelectPlan} />;

    case 1:
      return <ReviewOrder selectedPlan={selectedPlan!} onPressBack={() => setStep(0)} onPressNext={onPressNext} />;

    default:
      return <Plans onSelectPlan={onSelectPlan} />;
  }
});
