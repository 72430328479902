// import editIcon from 'assets/img/icons/EditBillingAddressIcon.svg';
import { UPGRADE_OR_DOWNGRADE_USER_PLAN_CACHE_KEY } from 'config/constants';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpgradeOrDowngradePlanMutation } from 'redux/auth/api';
import { useSelect } from 'utils/hooks/useSelect';
import { PlanType } from 'utils/types';

import AppButton from 'features/common/AppButton';
import styled from 'styled-components';

export const ReviewOrder = memo<{
  selectedPlan: PlanType;
  onPressBack: () => void;
  onPressNext: () => void;
}>(({ selectedPlan, onPressNext, onPressBack }) => {
  const { t } = useTranslation('account');

  const user = useSelect(({ me }) => me.user);

  const [, { isLoading: isUpgradingOrDowngradingPlanInProgress }] = useUpgradeOrDowngradePlanMutation({
    fixedCacheKey: UPGRADE_OR_DOWNGRADE_USER_PLAN_CACHE_KEY
  });

  // @render

  if (!selectedPlan) return null;
  return (
    <Container>
      <ReviewCard>
        <OrderSummary>
          <Title>{t('orderSummary')}</Title>
          <InformationRowContainer>
            <DefaultText>{selectedPlan.name}</DefaultText>
            <DefaultText>${selectedPlan.price}</DefaultText>
          </InformationRowContainer>
        </OrderSummary>
        <Billing>
          <Title>{t('billing')}</Title>
          <InformationRowContainer>
            <InformationColumnContainer>
              <DefaultText>{`${user?.first_name} ${user?.last_name}`}</DefaultText>
              <DefaultText>{user?.email}</DefaultText>
            </InformationColumnContainer>
            {/* <Edit
              src={editIcon}
              alt="edit billing address"
              onClick={() => {
                history.replace('/account/upgrade-plan/billing-address');
                setStep(1);
              }}
            /> */}
          </InformationRowContainer>
        </Billing>
      </ReviewCard>
      <Actions>
        <BackButton text={t('back')} onClick={onPressBack} disabled={isUpgradingOrDowngradingPlanInProgress} />
        <NextButton text={t('forward')} onClick={onPressNext} loading={isUpgradingOrDowngradingPlanInProgress} />
      </Actions>
    </Container>
  );
});

const DefaultText = styled.p`
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
`;

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.soft[40]};
  display: flex;
  align-items: center;
  flex-direction: column;

  justify-content: space-between;

  margin-top: 40px;
`;

const ReviewCard = styled.div`
  display: flex;
  flex-direction: column;

  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray[20]};
  border-radius: 8px;
  padding: 24px 20px;

  min-width: 420px;
`;

const OrderSummary = styled.div`
  padding-bottom: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[40]};
`;

const Billing = styled.div`
  padding-top: 24px;
`;

const Title = styled(DefaultText)`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.dark[70]};
  margin-bottom: 8px;
`;

const InformationRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InformationColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Actions = styled.div`
  display: flex;
`;

const NextButton = styled(AppButton)`
  margin-top: 48px !important;

  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10.5px 8px;
  gap: 10px;

  width: 200px;
  height: 40px;

  background: ${({ theme }) => theme.colors.dark[100]};
  border-radius: 4px;

  flex: none;
  order: 2;
  flex-grow: 0;
`;

const BackButton = styled(NextButton)`
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.purple[100]};
  margin-right: 20px;

  &:hover {
    color: ${({ theme }) => theme.colors.white};
  }
`;

/* const Edit = styled.img`
  cursor: pointer;
`;
 */
