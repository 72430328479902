import { PlanType } from './types';

export const PlanDetails: PlanType[] = [
  {
    id: 'starter_monthly',
    name: 'starter',
    price: 25,
    request_quota: 1000,
    article_quota: 10,
    charge_interval: 'monthly',
    is_cancelable: true,
    pros: [
      { name: 'templates', value: true },
      { name: 'languages_supported', value: true },
      { name: 'high_quality_text_generation', value: true },
      { name: 'image_generation', value: true },
      { name: 'fact_checking', value: false },
      { name: 'plagiarism_check', value: false },
      { name: 'higher_quality_translation', value: false },
      { name: 'higher_resolution_images', value: false },
      { name: 'onboarding_and_training_sessions', value: false },
      { name: 'dedicated_account_manager', value: false },
      { name: 'style_calibration', value: false },
      { name: 'content_agency', value: false }
    ],
    cost: [],
    price_id: ''
  },
  {
    id: 'starter_yearly',
    name: 'starter',
    price: 25,
    request_quota: 1000,
    article_quota: 10,
    charge_interval: 'yearly',
    is_cancelable: true,
    pros: [
      { name: 'templates', value: true },
      { name: 'languages_supported', value: true },
      { name: 'high_quality_text_generation', value: true },
      { name: 'image_generation', value: true },
      { name: 'fact_checking', value: false },
      { name: 'plagiarism_check', value: false },
      { name: 'higher_quality_translation', value: false },
      { name: 'higher_resolution_images', value: false },
      { name: 'onboarding_and_training_sessions', value: false },
      { name: 'dedicated_account_manager', value: false },
      { name: 'style_calibration', value: false },
      { name: 'content_agency', value: false }
    ],
    cost: [],
    price_id: ''
  },
  {
    id: 'blogger_monthly',
    name: 'blogger',
    price: 50,
    request_quota: 5000,
    article_quota: 50,
    charge_interval: 'monthly',
    is_cancelable: true,
    pros: [
      { name: 'templates', value: true },
      { name: 'languages_supported', value: true },
      { name: 'high_quality_text_generation', value: true },
      { name: 'image_generation', value: true },
      { name: 'fact_checking', value: true },
      { name: 'plagiarism_check', value: true },
      { name: 'higher_quality_translation', value: true },
      { name: 'higher_resolution_images', value: true },
      { name: 'onboarding_and_training_sessions', value: false },
      { name: 'dedicated_account_manager', value: false },
      { name: 'style_calibration', value: false },
      { name: 'content_agency', value: false }
    ],
    cost: [],
    price_id: ''
  },
  {
    id: 'blogger_yearly',
    name: 'blogger',
    price: 50,
    request_quota: 5000,
    article_quota: 50,
    charge_interval: 'yearly',
    is_cancelable: true,
    pros: [
      { name: 'templates', value: true },
      { name: 'languages_supported', value: true },
      { name: 'high_quality_text_generation', value: true },
      { name: 'image_generation', value: true },
      { name: 'fact_checking', value: true },
      { name: 'plagiarism_check', value: true },
      { name: 'higher_quality_translation', value: true },
      { name: 'higher_resolution_images', value: true },
      { name: 'onboarding_and_training_sessions', value: false },
      { name: 'dedicated_account_manager', value: false },
      { name: 'style_calibration', value: false },
      { name: 'content_agency', value: false }
    ],
    cost: [],
    price_id: ''
  },
  {
    name: 'enterprise',
    price: -1,
    request_quota: -1,
    article_quota: -1,
    id: '',
    charge_interval: 'unknown',
    is_cancelable: true,
    pros: [
      { name: 'templates', value: true },
      { name: 'languages_supported', value: true },
      { name: 'high_quality_text_generation', value: true },
      { name: 'image_generation', value: true },
      { name: 'fact_checking', value: true },
      { name: 'plagiarism_check', value: true },
      { name: 'higher_quality_translation', value: true },
      { name: 'higher_resolution_images', value: true },
      { name: 'onboarding_and_training_sessions', value: true },
      { name: 'dedicated_account_manager', value: true },
      { name: 'style_calibration', value: true },
      { name: 'content_agency', value: true }
    ],
    cost: [],
    price_id: ''
  }
];

export const AllPlanControlParameter = [
  'templates',
  'languages_supported',
  'high_quality_text_generation',
  'image_generation',
  'fact_checking',
  'plagiarism_check',
  'higher_quality_translation',
  'higher_resolution_images',
  'onboarding_and_training_sessions',
  'dedicated_account_manager',
  'style_calibration',
  'content_agency'
];
