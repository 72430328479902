import { produce } from 'immer';
import { Action, Thunk } from 'redux/store';
import { geoAPIBaseUrl } from '../config/constants';
import { apiService } from '../service/service';

type State = {
  googleTrends: string[];
};

export const initialState: State = {
  googleTrends: []
};

type ActionType = { type: 'trends/setTrends'; googleTrends: string[] } | Action<'me/logout'>;

export const trendsReducer = (state = initialState, action: ActionType): State => {
  switch (action.type) {
    case 'trends/setTrends':
      return produce(state, (draft) => {
        draft.googleTrends = action.googleTrends;
      });

    case 'me/logout':
      return initialState;

    default:
      return state;
  }
};

// @actions

export const fetchGoogleTrends = (): Thunk<ActionType> => (dispatch) =>
  fetch(geoAPIBaseUrl)
    .then(async (response) => {
      const currentGeo: { country_code: string } = await response.json();
      return currentGeo.country_code;
    })
    .then((countryCode) => apiService.fetchTrends(countryCode))
    .then((trends) => dispatch({ type: 'trends/setTrends', googleTrends: trends }))
    .observe('fetch_trends', dispatch)
    .catchError(dispatch);
