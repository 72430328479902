import { ReactComponent as NovusMiniSvg } from 'assets/img/NovusMini.svg';
import NovusWriterSvg from 'assets/img/novusWriter.svg';
import { ReactNode } from 'react';
import { Col, FlexCol, FlexRow, Label } from './Styles';

export const WelcomePageLayout = ({ children }: { children: ReactNode }) => {
  return (
    <FlexRow>
      <FlexCol>
        <Col style={{ alignItems: 'center', flex: 1, justifyContent: 'center' }}>
          <NovusMiniSvg color="#59159C" height={60} width={60} />
          <br />
          <img src={NovusWriterSvg} alt="Novus Logo" height="20px" />
        </Col>

        <FlexCol>{children}</FlexCol>

        <Col style={{ justifyContent: 'flex-end', flex: 1, paddingBottom: '10%' }}>
          <Label style={{ fontWeight: 700, fontSize: 14 }}>{`Copyright © Novus ${new Date().getFullYear()}`}.</Label>
        </Col>
      </FlexCol>

      <FlexCol style={{ background: '#000000 url("/welcomeBg.png")', height: '100vh' }} />
    </FlexRow>
  );
};
