import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { PlanType } from 'utils/types';

export const PlanCost = memo<{
  planType: boolean;
  plan: PlanType;
}>(({ planType, plan }) => {
  const { t } = useTranslation('account');

  // @handlers

  const getDiscount = (price: number, isMonthly = false) => {
    const discount = plan.discount ?? 0;

    if (isMonthly && planType) return `$${price - price * (discount / 100)}`;
    else if (isMonthly && !planType) return t(`monthlyBilled`, { price: (price - price * (discount / 100)) / 12 });
    else return t(`yearlyBilled`, { price: price - price * (discount / 100) });
  };

  // @render

  return (
    <StyledDiv>
      <StyledCost>
        <StyledPlanHeader>
          {plan.price !== -1 ? (
            <>
              {getDiscount(plan.price, true)} <br />
              {!planType && <StyledCostDesc>{getDiscount(plan.price)}</StyledCostDesc>}
            </>
          ) : (
            <ContactUsText>{t(`contactUs`)}</ContactUsText>
          )}
        </StyledPlanHeader>
      </StyledCost>
    </StyledDiv>
  );
});

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const StyledPlanHeader = styled.span`
  color: #213357;
  height: 90px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-left: 1px solid #cdd1de;
  text-align: center;
  font-size: 18px;
  background: ${({ theme }) => theme.colors.white};
`;

const StyledCost = styled.span`
  display: flex;
  width: 100%;
`;

const StyledCostDesc = styled.span`
  font-size: 16px;
  color: #a4acc4;
  display: block;
`;

const ContactUsText = styled.span`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
`;
