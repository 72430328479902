import React, { memo } from 'react';
import ContentLoader from 'react-content-loader';
import { useTheme } from 'styled-components';

export const PlanListSkeleton = memo<{ count: number }>(({ count = 1 }) => {
  const theme = useTheme();

  return (
    <ContentLoader width={'100vw'} height={'100vh'} backgroundColor={theme.colors.soft[70]}>
      <rect x={"7.5%"} y={194} rx="4" ry="4" width="23%" height="700px" />
      {Array.from({ length: count }, (_, i) => i).map((skeletonNum) => (
        <React.Fragment key={`plan-list-skeloton-${skeletonNum}`}>
          <rect x={`calc(${skeletonNum + 2} * 15.5%`} y={50} rx="4" ry="4" width="15%" height="50px" />
          <rect x={`calc(${skeletonNum + 2} * 15.5%`} y={102} rx="4" ry="4" width="15%" height="90px" />
          <rect x={`calc(${skeletonNum + 2} * 15.5%`} y={194} rx="4" ry="4" width="15%" height="700px" />
        </React.Fragment>
      ))}
    </ContentLoader>
  );
});
