import AppButton from 'features/common/AppButton';
import { AppCheckbox } from 'features/common/AppCheckbox';
import { memo, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getEntity } from 'redux/entity';
import styled from 'styled-components';
import { useSelect } from 'utils/hooks/useSelect';
import { setTextGenerationSettings } from 'redux/editor';
import { renderTemplateSettings } from 'utils/helpers/articleHelper';
import { TextLengthOption } from 'utils/types';
import { ArticleDrawerSelectBox } from './ArticleDrawerSelectBox';
import { ArticleDrawerTextLength } from './ArticleDrawerTextLength';
import { ArticleContext } from 'utils/contexts/ArticleContext';
import { Col } from '../../../common/Styles';

export const ArticleDrawerTextTemplateSettings = memo(() => {
  const { t } = useTranslation('article');
  const dispatch = useDispatch();
  const { articleId } = useContext(ArticleContext);

  const settings = useSelect(({ editor }) => editor.textGenerationSettings);
  const template = useSelect(({ editor, entity }) => {
    const templateId = editor.selectedTextTemplateId[articleId];
    return getEntity(entity, 'template', templateId);
  });

  // @handlers

  const handleTextLengthChange = useCallback(
    (textLength: TextLengthOption) => {
      dispatch(setTextGenerationSettings({ textLength }));
    },
    [dispatch]
  );

  const handleFactCheckLanguageChange = useCallback(
    (newValue: boolean) => {
      dispatch(setTextGenerationSettings({ fc_output_language: newValue }));
    },
    [dispatch]
  );

  // @render

  return (
    <div>
      <Divider />

      <ArticleDrawerSelectBox />

      <Divider />

      {renderTemplateSettings(template, 'suggestionTextLength') ? (
        <Col style={{ marginBottom: 20 }}>
          <SettingTitle>{t('suggestionTextLength')}</SettingTitle>
          <ArticleDrawerTextLength onChange={handleTextLengthChange} />
        </Col>
      ) : null}

      <FactAndOutputLangCheckbox
        value={!!settings.fc_output_language}
        text={t('factAndOutputSame')}
        onChange={handleFactCheckLanguageChange}
      />
    </div>
  );
});

const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.border[40]};
`;

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const ShrinkPanelButton = styled(AppButton)`
  margin-top: 24px;

  color: ${({ theme }) => theme.colors.primary[30]};
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
`;

const SettingTitle = styled.p`
  width: 182px;
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;

  color: ${({ theme }) => theme.colors.white};

  margin-bottom: 8px;
`;

const FactAndOutputLangCheckbox = styled(AppCheckbox)`
  margin: 0px -4px 0px -4px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.white};
  line-height: 16px;
`;
