import { Col, FlexRow, Label } from 'features/common/Styles';
import { orderBy } from 'lodash';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getEntities } from 'redux/entity';
import styled from 'styled-components';
import { useSelect } from 'utils/hooks/useSelect';
import { Template } from 'utils/types';
import { retrieveTemplateIcon, retrieveTemplateName } from '../../utils/helpers/articleHelper';

const ICON_FONT_SIZE = 32;
const ICON_MARGIN_BOTTOM = 16;

export const HomeQuickActions = memo<{
  onCreateArticle: (template?: Template) => void;
}>(({ onCreateArticle }) => {
  const { t } = useTranslation(['home']);

  const templateMeta = useSelect(({ entity }) => getEntities(entity, 'templateMeta'));
  const rawTemplates = useSelect(({ entity }) => getEntities(entity, 'template'));

  const templates = useMemo(() => {
    const templatesWithUsage = rawTemplates.map((template) => {
      const templateData = templateMeta.find((data) => data.template_id === template.id);
      const usage_count = templateData ? -templateData.usage_count : Number.MAX_SAFE_INTEGER;
      return { ...template, usage_count };
    });
    return orderBy(templatesWithUsage, ['usage_count', 'order'], ['desc', 'asc']).slice(0, 3);
  }, [templateMeta, rawTemplates]);

  // @handlers

  const handleCreateProject = useCallback(
    (template?: Template) => () => {
      onCreateArticle(template);
    },
    [onCreateArticle]
  );

  // @render

  return (
    <Col style={{ alignItems: 'flex-start', width: '100%' }}>
      <Title>{t('quickActions')}</Title>

      <ListContainer>
        <FlexRow style={{ width: '100%' }}>
          <ListItem onClick={handleCreateProject()}>
            <Label style={{ fontSize: ICON_FONT_SIZE, marginBottom: ICON_MARGIN_BOTTOM }}>📄</Label>
            <ListItemTitle>{t('createNewProject')}</ListItemTitle>
          </ListItem>
          <ListItem onClick={handleCreateProject()}>
            <Label style={{ fontSize: ICON_FONT_SIZE, marginBottom: ICON_MARGIN_BOTTOM }}>🔮</Label>
            <ListItemTitle>{t('customTemplate')}</ListItemTitle>
          </ListItem>

          {templates.map((template, index) => (
            <ListItem key={index} onClick={handleCreateProject(template)}>
              <Label
                style={{
                  fontSize: ICON_FONT_SIZE,
                  marginBottom: retrieveTemplateIcon(template) ? ICON_MARGIN_BOTTOM : 0
                }}
              >
                {retrieveTemplateIcon(template)}
              </Label>
              <ListItemTitle>{retrieveTemplateName(template, t)}</ListItemTitle>
            </ListItem>
          ))}
        </FlexRow>
      </ListContainer>
    </Col>
  );
});

const Title = styled(Label)`
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  padding-bottom: 20px;
`;

const ListContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 30px;
  padding-bottom: 20px;
`;

const ListItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 200px;
  height: 120px;
  padding: 26px;
  margin-right: 10px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.gray[20]};
  background-color: ${({ theme }) => theme.colors.gray[10]};
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  }
`;

const ListItemTitle = styled(Label)`
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  color: ${({ theme }) => theme.colors.dark[80]};
`;
