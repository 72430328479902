import { PlanType } from 'utils/types';
import api from 'redux/api';

const AccountApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPlans: build.query<PlanType[], void>({
      query: () => '/payment/getPlans',
      transformResponse: (response: { data: PlanType[] }) => response.data || null
    })
  }),
  overrideExisting: false
});

export const { useGetPlansQuery } = AccountApi;
export default AccountApi;
